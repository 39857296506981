@font-face {
  font-family: "monogram";
  src: url("./Microgramma.ttf");
}

@font-face {
  font-family: "artifakt";
  src: url("./ArtifaktElement-Regular.woff.ttf");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

#preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#speedometer {
  font-family: "artifakt";

  .speedometer__text {
    p {
      font-family: "artifakt";
      color: #000000;
      background-color: #000000;
    }
  }

  .speedometer__canvas {
    width: 100%;
    overflow: visible;
    height: auto;
    background-image: url(../public/assets/Home/speedoBg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }
}

#test {
  z-index: 99 !important;
  height: 100vh;
  Menu {
    overflow: visible;
    font-family: "artifakt";
  }
}

.basicLand {
  min-height: 100vh;
  @media screen and (max-width: 540px) {
    height: auto;
    min-height: auto;
  }
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    position: absolute;
    font-family: "monogram", sans-serif;
    font-size: 2.7rem;
    font-weight: 400;
    line-gap-override: 10px;
  }
  #side_nav {
    position: absolute;
    bottom: 0;
    left: 0;
    li {
      padding: 60px 20px;
      background-color: #fbfbfb76;
      transition: 200ms ease-in-out;
      &:hover {
        transition: 200ms ease-in-out;
        background-color: #fbfbfb;
      }
    }
  }
}

#nav {
  width: 100%;
  position: fixed;
  top: -10px;
  margin-top: 0 !important;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo img {
    height: 3vw;
    margin-left: 2vw;
    @media screen and (max-width: 540px) {
      height: 40px !important;
    }
  }
  // HamburgerMenu.scss

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;

    .bar {
      width: 30px;
      height: 3px;
      background-color: #000000;
      margin: 4px 0;
      transition: 0.4s;
    }

    &.active {
      .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-10px, 6px);
      }

      .bar:nth-child(2) {
        opacity: 0;
      }

      .bar:nth-child(3) {
        transform: rotate(45deg) translate(-10px, -6px);
      }
    }
  }

  overflow: visible;
  ul {
    height: auto;
    display: flex;
    justify-content: flex-end;
    gap: 2vw;
    align-items: center;
    width: auto;
    padding: 0 20px;
    // padding: 10px 5px !important;
    list-style: none;
    margin-top: 0 !important;
    // background-color: #ffffff4b;

    &.mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: white;
      z-index: 100 !important;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.4s ease-in-out;
      &.show {
        z-index: 100;
        opacity: 1;
        pointer-events: auto;
      }
      a {
        width: 100%;
        overflow: hidden;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        &.red {
          color: #bc0000;
          // text-decoration: underline;
          // border-bottom: 2px solid black;
        }
        height: 30px;
        padding: 0;
        font-size: 1rem;
        font-weight: 400;
        transition: 200ms ease;
        // &:first-child {
        //   margin-right: auto;
        //   padding-left: 0;
        // }
        &:hover {
          // background-color: rgba(164, 164, 164, 0.716);
          text-decoration: underline;
          transition: 200ms ease;
          // border-bottom: 1px solid black;
          &.logo {
            border: none;
          }
          // font-weight: 1000 !important;
        }
        li {
          font-weight: 500;
          font-family: "artifakt", sans-serif;
          white-space: nowrap;
        }
      }
    }

    @media screen and (max-width: 1300px) {
      gap: 10px;
    }
    a {
      overflow: hidden;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      &.red {
        color: #bc0000;
        // text-decoration: underline;
        // border-bottom: 2px solid black;
      }
      height: 4vw;
      padding: 0 0.8vw;
      font-size: 1vw;
      font-weight: 400;
      transition: 200ms ease;
      // &:first-child {
      //   margin-right: auto;
      //   padding-left: 0;
      // }
      &:hover {
        // background-color: rgba(164, 164, 164, 0.716);
        text-decoration: underline;
        text-decoration-thickness: 0.7em;
        // line-height: 2rem;
        transition: 200ms ease;
        // border-bottom: 1px solid black;
        &.logo {
          border: none;
        }
        // font-weight: 1000 !important;
      }
      li {
        font-weight: 500;
        font-family: "artifakt", sans-serif;
        white-space: nowrap;
      }
    }
  }
}

#Home {
  overflow: visible;
  position: relative;
  border-top: 1px solid black;
  font-family: "artifakt", sans-serif !important;
  background-color: #fff;
  .Landing {
    font-family: "monogram", sans-serif;
    background-image: url(../public/assets/Home/home_new.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }
    h1 {
      top: 30%;
      left: 35%;
      font-size: 5rem;
      padding: 0 10px;
      font-weight: 200 !important;
      width: auto;
      max-width: 80%;
      background-color: rgba(73, 72, 72, 0.233);
      text-align: center !important;
      transform: translate(-50%, -50%);
      color: white;
      @media screen and (max-width: 540px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 540px) {
        font-size: 2rem;
      }
      z-index: 1;
    }
    img {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 70vh;
      z-index: -0;
    }
    svg {
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%, -50%);
      // height: 20vh !important;
      height: 11vw !important;
      @media screen and (max-width: 540px) {
        top: 49%;
        height: 18vw !important;
        // font-size: 2rem;
      }
    }
  }
  #Home1 {
    // background: linear-gradient(180deg, #E5EAED 66.98%, rgba(217, 217, 217, 0) 80%);
    // background-color: rgb(20, 0, 58);
    img {
      position: absolute;
      top: 42%;
      left: 50%;
      width: 25vw;
      height: auto;
      transform: translate(-50%, -50%);
      @media screen and (max-width: 540px) {
        top: 48%;
        width: 40%;
      }
    }
  }
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tech {
    font-family: "artifakt", sans-serif;
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    overflow-x: hidden !important;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      overflow: hidden;
      overflow-x: hidden !important;
    }
    .left {
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      padding: 30px;
      background-color: #fff;
      background: linear-gradient(
        180deg,
        #ebebeb 0%,
        rgba(235, 235, 235, 0) 100%
      );
      overflow: hidden;
      @media screen and (max-width: 500px) {
        width: 100vw;
        height: fit-content;
        padding: 0;
        overflow: scroll;
        // flex-direction: column;
      }
      .title {
        // z-index: 100 !important;
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;
        // margin-right: 100% !important;
        align-items: center;
        flex-direction: row;
        overflow: hidden;
        width: 55vw;
        @media screen and (max-width: 500px) {
          width: 100%;
          // align-items: flex-end;
          justify-content: center;
          height: fit-content;
          overflow: visible;
          margin-left: 5%;
        }
        margin-bottom: 0;
        h1 {
          overflow: hidden;
          font-family: "artifakt", sans-serif;
          // margin: 20px;
          font-size: 1.8rem;
          font-weight: 600;
          margin-right: 10px;
          @media screen and (max-width: 500px) {
            overflow: visible;
            width: 60%;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
        svg{
          @media screen and (max-width: 500px) {
            width: 40%;
            
          }
        }
        hr {
          overflow: hidden;
          height: 0px !important;
          width: 60%;
          @media screen and (max-width: 500px) {
            width: 100%;
          }
          border: none; /* Removes the default border */
          border-top: 2px solid #000000;
        }
      }
      .content {
        overflow: visible;
        margin: 0px 20px;
        max-width: 40vw;
        // background-color: white;
        @media screen and (max-width: 500px) {
          max-width: 100vw;
          width: 100vw;
          height: fit-content;
          padding: 20px;
          margin: 0;
          margin-bottom: 30px;
          // flex-direction: column;
        }
        h1 {
          overflow: hidden;
          font-family: "artifakt", sans-serif;
          color: #870507;
          margin: 10px 0;
          font-size: 3vw;
          font-weight: 500;
          @media screen and (max-width: 540px) {
            font-weight: 400;
            font-size: 1.4rem;
            font-weight: 400;
          }
        }
        p {
          overflow: hidden;
          font-family: "artifakt", sans-serif;
          font-size: 0.6rem;
          margin-bottom: 10px;
          &.lower {
            font-size: 0.8rem;
          }
        }
        div {
          button {
            position: absolute;
          }
        }

        .statImg {
          overflow: visible;
        }
      }
      .btn {
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: end;
        button {
          overflow: hidden;
          margin: 20px;
          padding: 10px 20px;
          border: none;
          border: 1px solid black;
          background-color: transparent;
          width: 200px;
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          transition: 200ms ease;
          span {
            overflow: hidden;
            font-family: "artifakt", sans-serif;
            font-size: 1rem;
          }
          &:hover {
            background-color: black;
            color: white;
            box-shadow: 0 0 10px grey;
            transition: 200ms ease;
          }
        }
      }
    }
    .right {
      overflow: hidden;
      font-family: "artifakt", sans-serif !important;
      width: 45%;
      background: linear-gradient(180deg, #EBEBEB 0%, rgba(235, 235, 235, 0) 100%);

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: 500px) {
        width: 100vw;
        height: fit-content;
        margin-bottom: 5%;
        // flex-direction: column;
      }

      .tile {
        overflow: hidden;
        padding: 20px 40px;
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        background: linear-gradient(
          180deg,
          #e4e4e4 0%,
          rgba(255, 255, 255, 0) 100%
        );
        // &.odd{
        //     background-color: #E9EDF0;
        // }
        @media screen and (max-width: 500px) {
          padding: 10px;
          // flex-direction: column;
        }
        svg {
          overflow: hidden;
          width: 60px;
        }
        .content {
          overflow: hidden;
          width: 60%;
          @media screen and (max-width: 540px) {
            width: 95%;
          }
          // background: linear-gradient(
          //   180deg,
          //   #e4e4e4 0%,
          //   rgba(255, 255, 255, 0) 100%
          // );
          .top {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            svg {
              width: 30px;
              height: auto;
            }
          }
          h1 {
            overflow: hidden;
            font-size: 1.2rem;
            font-weight: 800;
            margin-bottom: 1em;
            @media screen and (max-width: 540px) {
              font-size: 0.9rem;
              margin-bottom: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .title {
    font-family: "artifakt", sans-serif;
    margin: 30px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.t {
      padding: 30px 40px;
    }
    @media screen and (max-width: 500px) {
      margin: 20px;

      margin-left: 0;
    }
    .title_h {
      display: flex;
      width: 700px;
      padding-right: 50px;
      font-family: "artifakt", sans-serif;
      .svg {
        display: flex;
        margin-right: 20px;
        align-items: center;
        @media screen and (max-width: 540px) {
          margin-right: 10px;
          margin-left: -70px;
        }
      }
      h1 {
        font-family: "artifakt", sans-serif;
        font-size: 2rem;
        font-weight: 300;
        span {
          font-family: "artifakt", sans-serif;
          font-weight: 600;
          margin: 10px;

          @media screen and (max-width: 540px) {
            margin: 0px;
          }
        }
        @media screen and (max-width: 500px) {
          font-size: 3.3vw;
        }
      }
      @media screen and (max-width: 500px) {
        margin-right: 0px;
        padding-right: 0px;
      }
    }
    button {
      font-family: "artifakt", sans-serif;
      padding: 5px 30px;
      border: none;
      border: 1px solid;
      border-image-source: linear-gradient(
        285.84deg,
        #ffffff 16.64%,
        rgba(255, 255, 255, 0.85) 77.79%
      );
      color: #870507;
      border-radius: 100px;
      margin-right: 5vw;
      //   font-weight: 500;
      font-weight: 700;
      transition: 200ms ease;
      background: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        ),
        linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.4)
        );
      box-shadow: 0px 4px 4px 0px #00000040;
      box-shadow: 0px 5px 4px 0px #d9d9d980 inset;
      &:hover {
        font-family: "artifakt", sans-serif;
        background-color: black;
        box-shadow: 0 0 10px grey;
        transition: 200ms ease;
      }
      @media screen and (max-width: 540px) {
        padding: 2px 10px;
        width: fit-content;
        min-width: 100px;
        font-weight: 500;
        font-size: 0.7rem;
      }
    }
  }
  .title_last {
    .title_h {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      h1 {
        font-size: 2rem;
        font-weight: 300;
        margin-left: 10%;
        width: 700px;
        @media screen and (max-width: 540px) {
          font-size: 1rem;
          white-space: nowrap;
        }
        span {
          font-weight: 600;
          margin: 10px;
        }
      }
      svg {
        height: auto;
        width: 75%;
      }
      hr {
        height: 0px !important;
        width: 90%;
        border: none; /* Removes the default border */
        border-top: 2px solid #000000; /* Sets the top border to a solid line */
      }
    }
  }
  .go_wireless {
    min-height: 100vh;
    @media screen and (max-width: 500px) {
      min-height: auto;
      height: fit-content;
      // flex-direction: column;
    }
    display: flex;
    // background-color: #fff;
    flex-direction: column;
    align-items: center;
    font-family: "artifakt", sans-serif;
    img {
      font-family: "artifakt", sans-serif;
      display: block;
      margin: 30px;
      height: 90vh;
    }
    .end_line {
      font-family: "artifakt", sans-serif;
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .embedVideo iframe {
      height: 100%;
      width: 10%;
      position: absolute;
      @media screen and (max-width: 500px) {
        //     width: 100%;
        //     height: fit-content !important;
      }
    }

    // .videoWrapper {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 100%;
    //   height: 115vh;
    //   border: none;
    //   padding: 0;
    //   margin: 0;
    //   @media screen and (max-width: 500px) {
    //     width: 100%;
    //     height: fit-content !important;
    //   }
    // }

    // .video-responsive {
    //   overflow: visible;
    //   padding-bottom: 56.25%;
    //   position: relative;
    //   height: 0;
    // }

    // .video-responsive iframe {
    //   left: 0;
    //   top: 0;
    //   height: 100%;
    //   width: 100%;
    //   position: absolute;
    // }

    @media (min-width: 1800px) {
      .videoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 112vh;
      }
    }
  }

  .features {
    overflow: visible;
    font-family: "artifakt", sans-serif;
    margin-bottom: 2%;
    .title {
      margin-bottom: 5vh;
      @media screen and (max-width: 540px) {
        margin: 0;
        margin-bottom: 5%;
      }
      // margin: 20vh 0px;
      background-color: #fff;
      font-family: "artifakt", sans-serif;
    }
    .content {
      font-family: "artifakt", sans-serif;
      width: 100%;
      overflow: visible;
      display: flex;
      justify-content: space-evenly;
      @media screen and (max-width: 540px) {
        flex-direction: column;
        width: 90vw;
        margin: 0 auto;
      }
      .tiles {
        overflow: visible;
        font-family: "artifakt", sans-serif;
        overflow: hidden;
        padding: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 540px) {
          width: 100%;
          padding: 10px;
          height: 70px;
          flex-direction: row;
          align-items: center;
          justify-content: start;
        }
        .technoFeature{
          @media screen and (max-width: 540px) {
            width: 13%;
            height: auto;
          }
        }
        img {
          overflow: visible;
          height: 70px;
          @media screen and (max-width: 540px) {
            width: 30px;
            height: auto;
            max-height: 50px;
            // max-height: 40px;
            margin-right: 10px;
          }
        }
        .smallIt {
          @media screen and (max-width: 540px) {
            // width: 13%;
            width: auto;
            max-height: 30px;
            // max-height: 40px;
            margin-right: 20px;
            // margin-left: 2%;
          }
        }
        p {
          font-family: "artifakt", sans-serif;
          font-size: 1.2rem;
          font-weight: 400;
          margin-top: 50px;
          @media screen and (max-width: 540px) {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .numbers {
    height: auto;
    margin-bottom: 5%;
    margin-top: 30px;
    @media screen and (max-width: 540px) {
      background-color: white;
    }
  }

  .speedometerS {
    margin-top: 2%;
    gap: 10vw;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      // height: 40vh;
      // width: 100vw;
    }

    .subDiv {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .num1,
      .num2,
      .num3 {
        font-size: 2.5rem;
        color: #fc7575;
      }

      .num1 {
        margin-top: -75%;
      }

      .num2 {
        margin-top: -60%;
      }

      .num3 {
        margin-top: -75%;
      }

      .subHead {
        margin-top: 1.5vh;
      }
    }
  }
  .product {
    display: flex;
    flex-direction: column;

    align-items: center;
    background-color: white;
    img {
      width: 90%;
      @media screen and (max-width: 540px) {
        width: 100%;
      }
      height: auto;
    }
    .second {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        margin: 30px 10px;
        color: #870507;
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          font-weight: 400;
          margin: 15px;
          font-size: 1.4rem;
          text-align: left;
        }
      }
      p {
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        @media screen and (max-width: 540px) {
          font-weight: 400;
          margin: 15px;
          font-size: 1rem;
          text-align: left;
        }
      }
    }
    .third {
      margin: 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;

      @media screen and (max-width: 540px) {
        flex-direction: column;
      }
      .left {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 60vw;

        @media screen and (max-width: 540px) {
          width: 100vw;
          height: fit-content;
        }
        .bigIt {
          svg {
            @media screen and (max-width: 540px) {
              width: 25% !important;
              // margin-right: 2%;
            }
          }
        }
        .innr {
          min-height: 220px;
          background: linear-gradient(
            180deg,
            rgba(196, 196, 196, 0.27) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
        & > div {
          padding: 10px;
          border: 3px solid rgb(255, 255, 255);
          margin: 10px;
          border-radius: 15px;
          box-shadow: 0px 5px 5px grey;
          & > div {
            display: flex;
            align-items: center;
            // gap: 10px;
            svg {
              font-size: 1.8rem;
              height: 40px;
              width: 40px;
              margin: 10px;
            }
            h1 {
              font-weight: bold;
            }
          }
        }
      }
      .right img {
        height: 100%;
        // background: linear-gradient(
        //   180deg,
        //   rgba(196, 196, 196, 0.27) 0%,
        //   rgba(255, 255, 255, 0) 100%
        // );
      }
    }
  }
  .use_case {
    margin-top: 10vh;
    margin-bottom: 10vh;
    background-image: url('../public/assets/Home/useCase.png');
    // background-color: #525050;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    @media screen and (max-width: 540px) {
      margin-top: 0;
      height: 70% !important;
    }
    // .use_case_grid {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 20px;
    //   justify-content: center;
    //   align-items: center;
    //   height: 100vh;

    //   @media screen and (max-width: 540px) {
    //     height: auto;
    //     margin-bottom: 3%;
    //     gap: 5px;
    //   }
    //   .part {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 70vw;
    //     padding: 10px;
    //     overflow: visible;

    //     @media screen and (max-width: 540px) {
    //       width: 100vw;
    //       padding: 0 10px;
    //     }
    //     & > div {
    //       position: relative;
    //       box-shadow: 0px 5px 10px rgba(128, 128, 128, 0.566);
    //       border-radius: 20px;
    //       transition: 200ms;

    //       @media screen and (max-width: 540px) {
    //         margin: 5px;
    //         border-radius: 5px;
    //       }
    //       &:hover {
    //         scale: 1.05;
    //         transition: 300ms;
    //       }
    //       h1 {
    //         position: absolute;
    //         bottom: 10px;
    //         color: white;
    //         // z-index: 2;
    //         width: 100%;
    //         text-align: center;

    //         &.left {
    //           text-align: right;
    //           padding-right: 10px;
    //         }
    //         text-shadow: 0px 0px 10px grey;
    //         font-weight: 700;
    //         font-size: 1.4rem;
    //         @media screen and (max-width: 540px) {
    //           font-weight: 600;
    //           font-size: 0.8rem;
    //         }
    //       }
    //     }
    //   }
    // }
    .use_case_grid {
      margin: 20px auto;
      margin-top: 10vh;
      width: 65vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      overflow: visible;
      @media screen and (max-width: 540px) {
        margin-top: -4%;
        width: 90vw;
      }
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
        margin: 5px;
        @media screen and (max-width: 540px) {
          margin: 0px;
        }
        a {
          overflow: visible;
          position: relative;
          box-shadow: 5px 5px 10px rgb(169, 169, 169);
          &.a1 {
            width: 29%;
          }
          &.a2 {
            width: 39%;
          }
          &.a3 {
            width: 29%;
          }
          &.a4 {
            width: 29%;
          }
          &.a5 {
            width: 35%;
          }
          &.a6 {
            width: 33%;
          }
          @media screen and (max-width: 540px) {
            margin: 4px;
          }
          p {
            position: absolute;
            bottom: 10px;
            font-size: 1.3rem;
            font-weight: 600;
            text-shadow: 0 0 10px black;
            z-index: 1;
            width: 100%;
            text-align: center;
            color: white;
            @media screen and (max-width: 540px) {
              font-size: 0.7rem;
              // margin-top: 0px;
            }
          }
          // border: 2px solid white;
          border-radius: 20px;

          overflow: hidden;
          img {
            width: 100%;
            height: 30vh;
            padding: 0;
            z-index: 1;
            @media screen and (max-width: 540px) {
              border: 1px solid white;
              // margin-top: 0px;
              border-radius: 5px;
              height: 30%;
            }
          }
        }
        // .g2 {
        //   width: 17.7vw;
        // }
      }
    }
  }

  .advantages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15%;
    @media screen and (max-width: 540px) {
      // z-index: 10 !important;
    }
    .one {
      width: 80vw;
      display: flex;
      justify-content: space-between;
      margin-bottom: 7%;
      margin-top: 2%;

      @media screen and (max-width: 540px) {
        // overflow: visible !important;
        width: 100vw;
        margin-top: -3%;
        z-index: 5 !important;
      }
      .left {
        
        
        h1 {
          font-size: 2.6rem;
          margin-bottom: 20px;
          @media screen and (max-width: 540px) {
            font-size: 1.4rem;
            text-align: center;
            margin: 1%;
            font-weight: 500;
            margin-bottom: 3%;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 25% !important;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 540px) {
            margin: 20px;
          }
          li {
            display: flex;
            justify-content: start;
            width: 90%;
            @media screen and (max-width: 540px) {
              width: 96%;
            }
            svg {
              // margin: 0 10px;
              width: 60px;
              height: auto;
              margin-right: 30px;
              @media screen and (max-width: 540px) {
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.3rem;
              span {
                color: #870507;
              }
              @media screen and (max-width: 540px) {
                font-size: 0.9rem;
                margin: 5px;
                font-weight: 500;
              }
            }
          }
        }
        .adPoints{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 50%;
        }
        li{
          margin-top: 3%;
          margin-bottom: 3%;
        }
      }
      .right {
        width: 65%;
        border-radius: 20px;
        // height: auto;
      }
    }

    .two {
      padding-bottom: 4%;
      h1 {
        margin: 40px 0px 10px 0px;
        font-size: 2.4rem;
        width: 100%;
        text-align: center;
        color: #870507;
        @media screen and (max-width: 540px) {
          font-size: 1.4rem;
          text-align: left;
          margin: 10px;
          font-weight: 400;
        }
      }
      p {
        width: 100%;
        font-size: 1.4rem;
        text-align: center;
        margin: 10px;
        @media screen and (max-width: 540px) {
          font-size: 0.9rem;
          text-align: left;
          margin: 10px;
          font-weight: 400;
        }
      }
      span {
        color: #870507;
      }
    }

    .three {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #E8E8E8;
      width: 100%;
      // margin-bottom: 30px;
      .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          font-size: 2rem;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            font-size: 4vw;
            margin: 1vw;
          }
        }
        p {
          font-size: 1.2rem;
          margin: 10px 0px;
          @media screen and (max-width: 540px) {
            font-size: 1.8vw;
            margin: 1vw;
          }
        }
        button {
          font-family: "artifakt", sans-serif;
          padding: 5px 30px;
          border: none;
          border: 1px solid;
          border-image-source: linear-gradient(
            285.84deg,
            #ffffff 16.64%,
            rgba(255, 255, 255, 0.85) 77.79%
          );
          color: #000;
          border-radius: 100px;
          margin-right: 5vw;
          //   font-weight: 500;
          font-weight: 700;
          font-size: 2vw;
          transition: 200ms ease;
          background: linear-gradient(
              285.84deg,
              #ffffff 16.64%,
              rgba(255, 255, 255, 0.85) 77.79%
            ),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 0.4)
            );
          box-shadow: 0px 4px 4px 0px #00000040;
          box-shadow: 0px 5px 4px 0px #d9d9d980 inset;
          color: #870507;
          @media screen and (max-width: 540px) {
            font-weight: 600;
            font-size: 2.2vw;
            padding: 0.6vw 5vw;
            margin: 0.5vw 1vw;
          }
          &:hover {
            font-family: "artifakt", sans-serif;
            background-color: black;
            box-shadow: 0 0 10px grey;
            transition: 200ms ease;
          }
        }
      }
      .right{
        margin-right: 5%;
      }
      @media screen and (max-width: 540px) {
        .right {
          width: 70vw;
        }
      }
    }
  }

  .partners {
    background-color: #fff;
    overflow: visible !important;

    .mobDivide{
      display: flex;
      .title{
        .title_h{
          h1{
            white-space: nowrap;
            // margin-top: 2% !important;
            z-index: 10 !important;
            overflow: visible !important;
            // margin-left: -50% !important;
          }
        }
      }
      
    }

    .top_devide {
      width: 40%;
      display: flex;
      justify-content: start;
      align-items: center;
      // margin-bottom: 20%;
      // margin: 30px 0px;
      // @media screen and (max-width: 540px) {
      //   margin-bottom: 50% !important;
      // }
      hr {
        height: 0px !important;
        width: 60%;
        border: none; /* Removes the default border */
        border-top: 2px solid #000000; /* Sets the top border to a solid line */
      }
    }

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lft,
      .rght {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 10vw;
        height: 40vh;
        .arrow {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .parteners_li {
        min-width: 80vw;
        width: 100%;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          overflow: hidden !important;
          height: 100%;
          width: 100%;
          margin: 20px;
          padding: 20px;
          @media screen and (max-width: 540px) {
            margin: 0;
            padding: 0;
            overflow: visible;
          }
        }
        .carouselKaItem {
          overflow: visible;
          width: 80vw;
          height: 30vh;
          img {
            overflow: visible;
          }
          .slick-dots {
            // overflow: visible;
          }
        }
      }
    }

    .mobPartner{
      margin: 5%;
      margin-top: -5%;
      overflow: visible;
    }

    .partnerImg{
      margin-top: 2%;
      overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;
        img{
          // z-index: 1 !important;
          overflow: visible;
          width: 20%;
          height: auto;
        }
        .sm{
          width: 5%;
        }
    }

    .bottom_devide {
      overflow: visible;
      width: 100%;
      display: flex;
      // z-index: 10 !important;
      justify-content: end;
      align-items: center;
      margin-top: 5%;
      // margin: 30px 0px;
      hr {
        height: 0px !important;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 2px solid #000000; /* Sets the top border to a solid line */
      }
    }
  }

  .finalyy {
    height: 100vh;
  }
}

#Charge {
  overflow: hidden;
  #Charge_landing {
    background-image: url(../public/assets/charge/charge_landing.png);
    width: 100% !important;
    height: 100vh;

    // position: relative;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
      width: 100vw;
      height: 60vh;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(217, 217, 217, 0) 100%
      );
      @media screen and (max-width: 540px) {
        height: 40vh !important;
        width: 100vw !important;
      }
    }

    @media screen and (max-width: 540px) {
      height: 40vh !important;
      width: 100vw !important;
    }
    h1 {
      position: absolute;
      font-family: "monogram", sans-serif;
      top: 20%;
      left: 10%;
      font-size: 2.2rem;
      font-weight: 400;
      width: 60vw;
      color: black;
      line-gap-override: 10px;
      @media screen and (max-width: 540px) {
        // margin-top: 25%;
        width: 80%;
        font-size: 3vw;
        top: 30%;
        left: 10%;
        font-weight: 600;
      }
    }
  }
  .web {
    // display: none !important;
    @media screen and (max-width: 540px) {
      display: none !important;
    }
  }

  .mob {
    @media screen and (min-width: 540px) {
      display: none !important;
      // width: 100vw !important;
    }
  }
  #Charge_page {
    height: 500vh;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(196, 199, 209, 0) 0%,
      #c4c7d1 100%
    );

    overflow: hidden;

    @media screen and (max-width: 540px) {
      height: 70vh;
    }

    .carSection {
      transition: transform 0.2s ease;
      overflow: hidden !important;
      z-index: 2;
      overflow-y: hidden;
      padding-top: 4vh;
      right: 7vw;
      width: 100vw;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      height: 100vh;
      position: relative;

      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    .heading {
      overflow: visible;
      width: 100%;
      text-align: center;
      align-items: center;
      font-family: "monogram", sans-serif;
      margin-top: 2.5vh;
      font-size: 3.5rem;
      z-index: 2;
      font-weight: 400;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 37%,
        rgba(116, 116, 116, 1) 91%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (max-width: 540px) {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
    .subHeading {
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: "artifakt", sans-serif;
      align-items: center; /* Center the content horizontally */
      margin-top: 4.5vh;
      font-weight: 400;
      font-size: 1.2rem;
      @media screen and (max-width: 540px) {
        font-size: 0.9rem;
        text-align: justify;
        margin-top: 2%;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        // white-space: nowrap;
      }
    }

    .carMob {
      margin-top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5%;
    }

    .cars {
      overflow: visible;
      align-self: center;
      // overflow: hidden;
      display: flex;
      height: 72vh;
      width: fit-content;
      align-items: center;
      .car {
        width: 35vw;
      }

      .charger {
        width: 20vw;
        margin-right: -8vw;
      }

      .initLine {
        width: 40vw;
        margin-left: -22vw;
      }

      .finalLine {
        position: absolute;
        width: 50vw;
        left: 23vw;
      }
    }
  }
  #Charge_about {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    min-height: 100vh;
    align-items: center;
    // background: rgb(211, 213, 222);
    // background: linear-gradient(
    //   90deg,
    //   rgba(211, 213, 222, 1) 1%,
    //   rgba(255, 255, 255, 1) 39%
    // );
    background-image: url(../public/assets/charge/aboutBg.png);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    .image {
      overflow: hidden;
      width: 30vw;
      padding: 50px;
      margin: 10px;
      img {
        height: 500px;
        @media screen and (max-width: 540px) {
          height: auto;
        }
      }

      @media screen and (max-width: 540px) {
        margin-top: 5%;
        width: 40%;
        padding: 0;
        margin: 20px;
        height: 10% !important;
      }
    }
    .about {
      width: 60vw;
      @media screen and (max-width: 540px) {
        width: 100%;
      }
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5% 0%;
        padding-top: 5%;
        padding-bottom: 5%;
        // margin: 20px 10px;
        // margin-top: 50px;
        // margin-bottom: 70px;
        @media screen and (max-width: 540px) {
          flex-direction: column;
          align-items: center;
        }
        img {
          margin: 5px 10px;
          width: 35%;
          @media screen and (max-width: 540px) {
            width: 25%;
          }
          // height: auto;
        }

        .chakr {
          width: 25%;
          @media screen and (max-width: 540px) {
            width: 15%;
          }
        }
        p {
          font-size: 1.1rem;
          font-family: "artifakt", sans-serif;
          @media screen and (max-width: 540px) {
            text-align: justify;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

#Footer {
  height: 700px;
  position: relative;
  font-family: "artifakt", sans-serif;
  .data {
    height: inherit;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: transparent;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: visible;
      svg {
        margin: 20px;
      }
      form {
        // border: 1px solid black;
        border-radius: 50px;
        padding: 10px 10px;
        padding: 5px;
        margin: 10px;
        border: 1px solid;
        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );
        box-shadow: 0 5px 10px grey;
        input {
          border: none;
          outline: none;
          background-color: transparent;
          padding: 5px 10px;
          font-size: 0.9rem;
          font-weight: 600;
          width: 300px;
        }
        button {
          border: none;
          // width: 100px;
          outline: none;
          padding: 10px 40px;
          background-color: #b53232;
          border-radius: 50px;
          color: white;
          transition: 100ms ease;
          font-size: 0.8rem;
          font-weight: 600;
          &:hover {
            transition: 100ms ease;
            background-color: rgb(255, 106, 106);
          }
        }
      }
    }
    .contacts {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .logo {
        width: 350px;
      }
      .contact {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        width: 60vw;
        align-content: center;
        @media (max-width: 600px) {
          & {
            grid-template-columns: 1fr;
          }
        }
        a {
          font-size: 1.2rem;
          display: block;
          text-decoration: none;
          font-size: 1.2rem;
          text-align: center;
          margin: 10px;
          font-weight: 600;
          color: black;
          &:hover {
            text-decoration: underline;
          }
        }
        .socials {
          display: flex;
          justify-content: center;
          gap: 10px;
          width: 100%;
        }
      }
    }
    .tnc {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .tnc_1 {
        a {
          display: block;
          margin: 10px;
          color: black;
          text-decoration: none;
          font-size: 1.2rem;
        }
      }
      .copy {
        width: max-content;
        h3 {
          font-weight: 400;
          margin: 5px 0px;
        }
        div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 30px;
          p {
            margin: 5px 0px;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  img.footer_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
    z-index: -1;
  }
}
#Footer_mob {
  height: 500px;
  position: relative;
  font-family: "artifakt", sans-serif;
  @media screen and (max-width: 540px) {
    height: max-content;
  }
  .data {
    // height: inherit;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    // align-items: center;
    background: transparent;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: visible;
      svg {
        margin: 20px;
      }
      form {
        // border: 1px solid black;
        border-radius: 50px;
        padding: 5px;
        margin: 10px;
        border: 1px solid;
        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );
        box-shadow: 0 5px 10px grey;
        display: flex;
        input {
          border: none;
          outline: none;
          background-color: transparent;
          padding: 1vw 10px;
          font-size: 3vw;
          font-weight: 600;
          width: 100%;
          @media screen and (max-width: 540px) {
          }
        }
        button {
          border: none;
          width: 30vw !important;
          outline: none;
          padding: 1vw 1vw;
          background-color: #b53232;
          border-radius: 50px;
          color: white;
          transition: 100ms ease;
          font-size: 3vw;
          font-weight: 500;
          width: max-content;
          text-align: center;
          &:hover {
            transition: 100ms ease;
            background-color: rgb(255, 106, 106);
          }
        }
        @media screen and (max-width: 540px) {
          width: 80vw;
        }
      }
    }
    .contacts {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .logo {
        width: 350px;
        @media screen and (max-width: 540px) {
          width: 50vw;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 10px;
        padding: 10px;
        a {
          font-size: 0.8rem;
          display: block;
          text-decoration: none;
          font-weight: 600;
          color: black;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .contact {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 5px;
        width: 60vw;
        align-content: center;
        @media (max-width: 600px) {
          width: 50vw;
          & {
            grid-template-columns: 1fr;
          }
        }
        a {
          font-size: 1.2rem;
          display: block;
          text-decoration: none;
          text-align: center;
          margin: 10px;
          font-weight: 600;
          color: black;
          &:hover {
            text-decoration: underline;
          }
          @media screen and (max-width: 540px) {
            margin: 5px;
            font-size: 1rem;
          }
        }
        .socials {
          display: flex;
          justify-content: center;
          gap: 10px;
          width: 100%;
        }
      }
    }
    .tnc {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      overflow: scroll;

      // background-image: url(../public/assets/footer.png);

      @media screen and (max-width: 540px) {
        padding: 0;
        margin-bottom: 100px;
      }
      .tnc_1 a {
        display: block;
        margin: 10px;
        color: black;
        text-decoration: none;
        font-size: 1.2rem;
      }
      .copy {
        width: max-content;
        h3 {
          font-weight: 400;
          margin: 5px 0px;
          @media screen and (max-width: 540px) {
            font-size: 0.8rem;
            text-align: center;
            padding: 10px;
          }
        }
        div.link {
          display: flex;
          width: 100%;
          justify-content: space-between;
          // margin-bottom: 30px;
          p {
            margin: 5px 0px;
            font-weight: 600;
            width: 100vw;
            &:hover {
              text-decoration: underline;
            }
            text-align: right;
            padding-right: 10px;
            // @media screen and (max-width: 540px) {
            //   & {
            //     // margin-bottom: 10px;
            //     text-align: right;
            //     padding-right: 10px;
            //   }
            // }
          }
        }
        @media screen and (max-width: 540px) {
          & > p {
            text-align: center;
          }
        }
        .contact {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          margin: 10px;
        }
      }
    }
  }
  img.footer_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
    z-index: -1;
  }
}

#Technology {
  #TechLanding {
    .landing {
      video {
        width: 100%;
        height: auto;
      }
    }
  }

  .product {
    height: 30vh;
    @media screen and (max-width: 540px) {
      & {
        height: auto;
      }
    }
    .canvas {
      height: 100vh;
      overflow: visible;
    }
    .productHead {
      margin-top: 20vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 540px) {
        margin-top: 20px;
      }
      svg {
        width: 100px;
        height: auto;
        @media screen and (max-width: 540px) {
          width: 40px;
          height: auto;
        }
      }
      .heading {
        overflow: hidden;
        font-size: 34px;
        font-weight: 600;
        font-family: "artifakt", sans-serif;
        color: rgb(0, 0, 0);
        padding-left: 2vh;
        @media screen and (max-width: 540px) {
          font-size: 1.1rem;
        }
      }
      hr {
        height: 5px;
        width: 20%;
        border: none; /* Removes the default border */
        border-top: 5px solid #cd222a; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 18px;
        width: 20px;
        background-color: #cd222a;
      }
    }
  }

  .carCanvas {
    height: 100vh;
    width: 90%;
    margin: 10px auto;
    overflow: visible;
    padding-bottom: 5%;
    background-color: #fcfcfc;
    @media screen and (max-width: 540px) {
      height: 70% !important;
      & {
        height: fit-content;
      }
    }
    .imageCar {
      display: flex;
      justify-content: center;
      align-items: center;

      .car {
        width: 40vw;
      }

      .left {
        width: 20vw;
        margin-right: -4vw;
        margin-top: 15vh;
      }
      .right {
        width: 25vw;
        margin-left: -5vw;
        margin-top: 20vh;
      }
    }
  }

  .working {
    font-family: "artifakt", sans-serif;
    height: 300%;
    .headingTop {
      overflow: visible;
      font-size: 60px;
      font-family: "monogram", sans-serif;
      color: black;
      margin-left: 9.1%;
      @media screen and (max-width: 540px) {
        & {
          margin-left: 5vw;
          font-size: 1.5rem;
        }
      }
    }

    .workingHead {
      overflow: visible;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 540px) {
        & {
          margin-bottom: 2vh;
        }
      }
      .heading {
        overflow: hidden;
        font-size: 2.5rem;
        font-family: "monogram", sans-serif;
        align-self: baseline;
        margin: 0 2vw;
        text-rendering: unset;
        @media screen and (max-width: 540px) {
          font-size: 1.3rem;
        }
        span {
          color: #cd222a;
        }
        @media screen and (max-width: 540px) {
          & {
            font-size: 1.1rem;
          }
        }
      }
      svg {
        width: 500px;
        height: auto;
        @media screen and (max-width: 540px) {
          width: 100px;
        }
      }

      hr {
        height: 5px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 5px solid #cd222a; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 20px !important;
        width: 20px !important;
        border-radius: 30px;
        background-color: #cd222a;
      }
    }

    .division {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .explanation {
      overflow: hidden;
      display: grid;
      grid-template-columns: 49vw 2vw 49vw;
      grid-template-rows: repeat(1, 240vh);
      justify-items: center;
      gap: 5px;
      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      img {
        max-width: 100%; /* Set the maximum width of the image to 100% of the container */
        max-height: 100%; /* Set the maximum height of the image to 100% of the container */
      }

      .illustration {
        width: 45vw;
        height: 60vh;
        @media screen and (max-width: 540px) {
          width: 90vh;
          height: auto;
        }
      }
      .txt {
        width: 40vw;
        font-size: 1.2rem;
        min-height: 30vh;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          width: 90vw;
        }
      }

      .fstCol {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 5vh;
        @media screen and (max-width: 540px) {
          width: 90vw;
        }
      }

      .scndCol {
        overflow: hidden;
        // background-image: url(../public/assets/Technology/Vector89.png);
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 250%;
        // max

        .bg {
          align-self: flex-start;
          z-index: 0;
        }

        .svgs {
          overflow: visible;
          z-index: 5;
          min-height: 100%;
          max-height: 210%;
          position: absolute;
          svg {
            overflow: hidden;
            z-index: 10;
          }
        }
      }

      .thdCol {
        overflow: hidden;
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        gap: 5vh;
        @media screen and (max-width: 540px) {
          margin-top: 0vh;
        }
      }

      .illustration.thd {
        width: 37vw;
        @media screen and (max-width: 540px) {
          width: 100%;
        }
        align-self: center;
      }

      .thd.txt {
        align-self: baseline;
        padding-top: 18vh;
        padding-bottom: 2vh;
        overflow: hidden;
        @media screen and (max-width: 540px) {
          padding-top: 2vh;
        }
      }

      .frth.txt {
        // align-self: baseline;
        padding-top: 18vh;
        overflow: hidden;
        min-height: 45vh;
        @media screen and (max-width: 540px) {
          min-height: auto;
          margin-bottom: 30px;
          padding-top: 2vh;
        }
      }

      .illustration.frth {
        padding-top: 4vh;
        overflow: hidden;
        width: 40vw;
        @media screen and (max-width: 540px) {
          padding-top: 2vh;
          width: 90vw;
        }
        align-self: flex-start;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      justify-content: end;
      button {
        margin: 20px;
        padding: 10px 20px;
        border: none;
        border: 1px solid black;
        background-color: transparent;
        width: auto;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        transition: 200ms ease;
        background: linear-gradient(
            285.84deg,
            #ffffff 16.64%,
            rgba(255, 255, 255, 0.85) 77.79%
          ),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.4)
          );
        border: 1px solid;

        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );

        box-shadow: 0px 4px 4px 0px #00000040;

        box-shadow: 0px 5px 4px 0px #d9d9d980 inset;

        @media screen and (max-width: 540px) {
          margin: 10px auto;
        }
        span {
          font-family: "artifakt", sans-serif;
          font-size: 1.1rem;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            font-size: 0.8rem;
          }
        }
        &:hover {
          background-color: white;
          transition: 200ms ease;
        }
      }
    }
  }
  .part_2 {
    .container {
      font-family: "artifakt";
      width: 55%;
      padding: 20px;
      box-shadow: 0 5px 10px gray;
      margin: 20px;
      border-radius: 20px;
      margin: 20px auto;
      transform: translateY(40px);
      background-color: rgba(243, 243, 243, 0.396);
      margin-bottom: -100px;
      /* Rectangle 320 */

      background: rgba(255, 255, 255, 0.6);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
backdrop-filter: blur(2px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 20px;

box-sizing: border-box;

// position: absolute;
// width: 1022px;
// height: 376px !important;
// left: 200px;
// top: 3504px;



      @media screen and (max-width: 540px) {
        font-size: 0.6rem;
        padding: 5px;
        margin: 5px auto;
        margin-bottom: -10px;
        width: 80vw;
      }
      h1 {
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
        @media screen and (max-width: 540px) {
          font-size: 0.9rem;
        }
      }
      p {
        font-size: 1.7rem;
        text-align: justify;
        padding-top: 2%;
        padding-left: 7%;
        padding-right: 7%;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          font-size: 0.6rem;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  .why {
    overflow: hidden;
    height: auto;
    background-color: #f6f6f6;
    display: flex;
    padding: 20px;
    position: relative;
    @media screen and (max-width: 540px) {
      height: auto;
      width: 100vw;
    }
    .charger {
      position: absolute;
      left: 45%;
      transform: translate(-50%, 0%);
      bottom: 8%;
      width: 15%;
      z-index: 2;
      height: auto;
      @media screen and (max-width: 540px) {
        height: 150px;
      }
    }
    .whyText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 30vw;
      font-family: "monogram", sans-serif;
      font-size: 72px;
      // width: 40px;
      @media screen and (max-width: 540px) {
        min-width: 60px;
      }

      h1 {
        margin: 0;
        padding: 0;
        line-height: 100%;
        @media screen and (max-width: 540px) {
          font-size: 1.2rem;
        }
      }
    }
    .picGrid {
      padding: 0;
      min-width: 65vw;
      // background-color: #C2747B;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      // grid-template-rows: repeat(3, 20vw);
      // grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      // grid-row-gap: 0px;
      // margin-right: 100px;
      // align-content: space-evenly;
      overflow-x: visible;

      .r1 {
        display: flex;
        width: 60%;
        gap: 5%;
        overflow: visible;
        height: 30%;
      }
      .r2 {
        display: flex;
        width: 70%;
        gap: 5%;
        overflow: visible;
        height: 25%;
      }
      .r3 {
        display: flex;
        width: 80%;
        height: 25%;
        gap: 5%;
        overflow: visible;
      }
      @media screen and (max-width: 540px) {
        margin-right: 0;
        gap: 5px;
        grid-row-gap: 5px;
        min-width: auto;
      }

      img {
        padding: 0;
        z-index: 1;
      }

      .g4 {
        grid-row-start: 2;
        grid-row-end: 4;
      }

      .g5 {
        grid-column: 2 / span 2;
      }
    }
  }
}

#Contact_page {
  .Landing {
    font-family: "monogram", sans-serif;
    background-image: url(../public/assets/Contact/contact_bg.png);
    position: relative;
    h1 {
      top: 30%;
      left: 50%;
      font-size: 2rem;
      text-align: center;
      font-weight: 400;
      width: 90%;

      transform: translate(-50%, -50%);
      color: white;
      z-index: 1;
      @media screen and (max-width: 540px) {
        font-size: 1.2rem;
      }
    }
    .map {
      position: absolute;
      top: 70%;
      left: 50%;
      height: 40%;
      transform: translate(-50%, -50%);
      width: 50vw;
      border-radius: 5px;
      overflow-x: hidden;
      &::-webkit-scrollbar-track {
        background: rgba(
          255,
          255,
          255,
          0.163
        ); /* Make scrollbar track invisible */
      }

      /* Define scrollbar width */
      &::-webkit-scrollbar {
        width: 5px; /* Set scrollbar width */
      }

      /* Define scrollbar thumb */
      &::-webkit-scrollbar-thumb {
        background-color: #888; /* Color of the thumb */
        border-radius: 10px; /* Roundness of the thumb */
      }
      @media screen and (max-width: 540px) {
        width: 80vw;
      }
    }
  }
  .contact_inner {
    display: flex;
    // background-image: url(../public/assets/Contact/contact_1.png);
    // background-repeat: no-repeat;
    // background-size: contain;

    background-color: rgb(214, 214, 214);
    background: rgb(159, 159, 159);
    background: radial-gradient(
      circle,
      rgb(200, 200, 200) 0%,
      rgba(255, 255, 255, 0) 48%,
      rgba(203, 203, 203, 1) 100%
    );
    flex-direction: column;
    padding-bottom: 50px;
    align-items: center;
    .contact_head {
      font-family: "artifakt", sans-serif;
      height: 40vh;
      &.odd {
        height: 25vh;
        margin: 5vh;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: 540px) {
        height: auto;
        width: 90vw;
      }
      button {
        font-family: "artifakt", sans-serif;
        padding: 10px 30px;
        border: 1px solid black;
        font-size: 1.2rem;
        font-weight: 600;
        background: linear-gradient(
            285.84deg,
            #ffffff 16.64%,
            rgba(255, 255, 255, 0.85) 77.79%
          ),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.4)
          );
        border: 0.7px solid;

        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );
        box-shadow: 0px 4px 40px #000000;

        box-shadow: 0px 5px 4px 0px #d9d9d980 inset;
        width: 40vw;
        border-radius: 50px;
        @media screen and (max-width: 540px) {
          padding: 5px 15px;
          font-size: 1rem;
          width: 80vw;
          margin: 20px 0;
        }
      }
      p {
        font-family: "artifakt", sans-serif;
        width: 60vw;
        font-size: 1.1rem;
        text-align: center;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          font-weight: 600;
          font-size: 0.8rem;
          width: 90vw;
        }
      }
    }
    .lets_Talk {
      font-family: "artifakt", sans-serif;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 80vw;
      border-radius: 50px;
      height: 60vh;
      box-shadow: 0px 2px 4px 0px #00000040;
      border: 0.1px solid;
      border-image-source: linear-gradient(
        30.93deg,
        #ffffff 8.61%,
        #aea4a4 8.62%,
        #ffffff 76.69%
      );
      box-shadow: 0px 4px 4px 0px #ffffff inset;

      box-shadow: 0px 4px 4px 0px #00000040;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.4) 2.87%,
        rgba(255, 255, 255, 0) 100%
      );
      @media screen and (max-width: 540px) {
        height: auto;
        border-radius: 5px;
        padding: 10px;
        width: 90vw;
        margin: 20px 0px;
      }
      .contact {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        h1.main {
          font-family: "artifakt", sans-serif;
          font-size: 3rem;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            font-size: 1.4rem;
          }
        }
        h1 {
          font-family: "artifakt", sans-serif;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            font-size: 1rem;
          }
        }
        .c {
          font-family: "artifakt", sans-serif;
          display: flex;
          gap: 1rem;
          align-items: center;
          @media screen and (max-width: 540px) {
            font-size: 0.6rem;
          }
        }
      }
      form.form {
        font-family: "artifakt", sans-serif;
        width: 300px;
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media screen and (max-width: 540px) {
          padding: 0px 5px;
          width: 50%;
        }
        h1 {
          font-family: "artifakt", sans-serif;
          text-align: end;
          margin: 30px 0px;
          @media screen and (max-width: 540px) {
            font-size: 1.2rem;
          }
        }
        p {
          font-family: "artifakt", sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          @media screen and (max-width: 540px) {
            font-size: 0.8rem;
          }
        }
        input {
          font-family: "artifakt", sans-serif;
          border: none;
          background-color: transparent;
          border-bottom: 1px solid black;
          margin: 10px 0px;
          @media screen and (max-width: 540px) {
            margin: 5px 0px;
            font-size: 0.8rem;
          }
        }
        .share {
          font-family: "artifakt", sans-serif;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .socials {
            font-family: "artifakt", sans-serif;
            display: flex;
            gap: 1rem;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .qna {
    background-color: white;
    box-shadow: 0 5px 5px grey;
    padding: 30px 60px;
    border-radius: 10px;
    @media screen and (max-width: 540px) {
      padding: 5px 10px;
      border-radius: 5px;
    }
    .link {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 10px;
      @media screen and (max-width: 540px) {
        gap: 5px;
      }
      button {
        border: 0.7px solid;

        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );
        // box-shadow: 0px 4px 10px #000000;
        box-shadow: 0px 5px 4px 0px #d9d9d980 inset;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 20px;
        @media screen and (max-width: 540px) {
          font-size: 0.8rem;
          padding: 4px 8px;
        }
      }
    }
    .qna-item {
      font-family: "artifakt", sans-serif;
      margin-bottom: 10px;
      position: relative;

      .qna-question {
        font-family: "artifakt", sans-serif;
        border-bottom: 1px solid black;
        width: 500px;
        padding: 10px 20px;
        font-weight: 600;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          padding: 4px 8px;
          font-size: 0.8rem;
          width: 90vw;
        }
      }

      .qna-answer {
        font-family: "artifakt", sans-serif;
        height: 0px;
        width: 500px;
        @media screen and (max-width: 540px) {
          width: 90vw;
          font-size: 0.7rem;
        }
        &.expanded {
          height: auto !important;
          padding: 10px 20px;
          @media screen and (max-width: 540px) {
            padding: 4px 8px;
          }
        }
      }
    }
  }
}

.Landing {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 540px) {
    height: 40vh;
    width: 100vw;
  }
  h1 {
    position: absolute;
    font-family: "monogram", sans-serif;
    line-gap-override: 10px;
  }
  #side_nav {
    position: absolute;
    bottom: 0;
    left: 0;
    li {
      padding: 60px 20px;
      background-color: #fbfbfb76;
      transition: 200ms ease-in-out;
      border-bottom: 1px rgb(85, 85, 85);
      &:hover {
        transition: 200ms ease-in-out;
        background-color: #fbfbfb;
      }
    }
  }
}

#UseCase {
  // .landing {
  //   background-image: url(../public/assets/UseCase/Landing.png);
  //   // text-align: center;
  //   h1 {
  //     background: rgb(0, 0, 0);
  //     background: linear-gradient(
  //       180deg,
  //       rgba(0, 0, 0, 1) 37%,
  //       rgba(116, 116, 116, 1) 91%
  //     );
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     top: 40%;
  //     left: 40%;
  //     font-size: 2.7rem;
  //     font-weight: 400;
  //     transform: translate(-50%, -50%);
  //   }
  // }

  .userBase {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    background-image: url(../public/assets/UseCase/usecase_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      margin-top: 10vw;
      height: 40vh;
      // margin-top: 0px;
    }
    & > h1 {
      width: 70vw;
      font-family: "Artifakt";
      font-style: normal;
      font-weight: 800;
      margin-top: 20px;
      font-size: 2.4rem;
      // font-style: to-upper-case($string: );
      text-transform: uppercase;
      // line-height: 31px;
      text-align: start;
      line-height: normal;
      color: #000000;
    }

    .useCaseGrid {
      width: 65vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      overflow: visible;
      @media screen and (max-width: 540px) {
        // margin-top: 0px;
        width: 90vw;
      }
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
        margin: 5px;
        @media screen and (max-width: 540px) {
          margin: 5px;
        }
        a {
          overflow: visible;
          position: relative;
          &.a1 {
            width: 29%;
          }
          &.a2 {
            width: 39%;
          }
          &.a3 {
            width: 29%;
          }
          &.a4 {
            width: 29%;
          }
          &.a5 {
            width: 35%;
          }
          &.a6 {
            width: 33%;
          }
          p {
            position: absolute;
            bottom: 10px;
            font-size: 1.3rem;
            font-weight: 600;
            text-shadow: 0 0 10px black;
            z-index: 1;
            width: 100%;
            text-align: center;
            color: white;
            @media screen and (max-width: 540px) {
              font-size: 0.7rem;
              // margin-top: 0px;
            }
          }
          border: 2px solid white;
          border-radius: 20px;

          @media screen and (max-width: 540px) {
            border-radius: 5px;
            height: 100%;
            // margin: 4px;
          }
          overflow: hidden;
          img {
            width: 100%;
            height: 30vh;
            padding: 0;
            z-index: 1;
            @media screen and (max-width: 540px) {
              border: 1px solid white;
              // margin-top: 0px;
              border-radius: 5px;
              height: 100%;
            }
          }
        }
        // .g2 {
        //   width: 17.7vw;
        // }
      }
    }
  }

  .downHeading {
    font-family: "Artifakt";
    font-style: bold;
    font-size: 20px;
    // line-height: 31px;
    margin: 20px 10px;
    text-align: center;
    color: black;
    font-weight: 500;
    @media screen and (max-width: 540px) {
      font-size: 2.5vw;
      font-weight: 600 !important;
      // line-height: 31px;
      margin: 10px 5px;
      // margin-top: 0px;
    }
  }
}

#Fleets {
  .landing {
    background-image: url(../public/assets/Fleets/fleet_landing.png);
    font-family: "monogram", sans-serif;
    position: relative;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }
    h1 {
      top: 80%;
      left: 46%;
      font-size: 2.2vw;
      font-weight: 400;
      width: 75%;
      @media screen and (max-width: 540px) {
        margin-top: 25%;
        width: 100%;
        margin-left: 5%;
        font-size: 0.85rem;
        top: 40%;
      }

      transform: translate(-50%, -50%);
      background: rgb(0, 0, 0);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1;
    }
  }

  .web {
    // display: none !important;
    @media screen and (max-width: 540px) {
      display: none !important;
    }
  }

  .mob {
    @media screen and (min-width: 540px) {
      display: none !important;
      // width: 100vw !important;
    }
  }

  .nextPg {
    // max-width: 100%; /* Set the maximum width of the container */
    // max-height: 100%;
    height: auto;
    overflow: hidden;

    background: linear-gradient(
      0deg,
      #bca7b1 -26.12%,
      rgba(188, 167, 177, 0) 99.6%
    );

    .carMob {
      overflow: visible;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      scale: 0.5;
      z-index: 5;
      bottom: -60%;
    }

    @media screen and (max-width: 540px) {
      background-image: url(../public/assets/Fleets/fleetBg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .txt {
      z-index: 5;
      display: flex;
      gap: 5vw;
      margin: auto 7vw;
      margin-top: 5vh;
      font-family: "artifakt", sans-serif;
      overflow: visible;
      margin-bottom: 10%;

      @media screen and (max-width: 540px) {
        flex-direction: column;
        gap: 5vh;
        margin-bottom: 70%;
      }

      .head {
        z-index: 1;
        white-space: nowrap;
        overflow: visible;
        text-align: center;
        font-weight: 700;
        margin-bottom: 5vh;
        font-size: 1.15rem;
        @media screen and (max-width: 540px) {
          font-size: 1rem;
          margin-bottom: 1%;
        }
      }

      .subHead {
        text-align: justify;
        font-size: 16px;
        z-index: 1;
        @media screen and (max-width: 540px) {
          font-size: 0.8rem;
        }
      }
      .thd {
        overflow: visible;
      }
      .scnd {
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 5vw;
        overflow: visible;
      }
    }
    .road {
      overflow: hidden;
      display: flex;
      gap: 5vw;
      justify-content: center;
      align-items: flex-end;
      margin-top: -90vh;
      z-index: -1;

      img {
        height: auto;
        width: 20vw;
        z-index: 0;
      }

      .outerContainer {
        overflow: hidden;
        display: flex;
        height: auto;
        flex-direction: column;

        .car1 {
          width: 5rem;
        }

        .car2 {
          width: 6.6rem;
        }

        .car3 {
          z-index: 55;
          width: 4.1rem;
        }

        .carContainer1 {
          align-self: flex-end;
          position: relative;
          display: flex;
          justify-content: flex-start;
          transform: translate(5%, 200%);
          // margin-top: -50%;
          margin-right: 5%;
          z-index: 1;
          // transform: translateY(-500px);
          // z-index: ;
        }

        .carContainer2 {
          justify-self: flex-start;
          align-self: center;
          position: relative;
          display: flex;
          justify-content: center;
          margin-top: -50%;
          // padding-bottom: 50%;
          transform: translate("0%", "800%") !important;
        }

        .carContainer3 {
          margin-top: 50vh;
          position: relative;
          align-self: flex-start;
          display: flex;
          justify-content: flex-start;
          // transform: translate(5%, 100%);
          margin-bottom: -120%;
          margin-left: 8%;
          // margin-top: -50%;
          // margin-right: 15%;
          z-index: 1;
          // transform: translateY(-500px);
          // z-index: ;
        }
      }
    }
    .optimDiv {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100vw;
      margin-top: -45vh;
      margin-bottom: 13vh;

      .optimTxt {
        width: 50vw;
      }
    }
  }

  .lastPg {
    height: auto;
    overflow: hidden;

    .btmRoads {
      overflow: hidden;
      display: flex;
      gap: 8vw;
      justify-content: center;
      align-items: flex-start;

      img {
        height: auto;
        width: 9vw;
      }

      .left,
      .right {
        width: 7vw;
      }

      .left {
        position: relative;
        right: 2.2vw;
      }

      .right {
        position: relative;
        left: 2.2vw;
      }
    }
  }
}

#Blogs {
  background: linear-gradient(
    180deg,
    #bcc6d2 -25.46%,
    rgba(227, 232, 236, 0.19) 79.4%
  );
  @media screen and (max-width: 540px) {
    background: white;
    hr {
      display: none;
    }
  }
  position: relative;
  .top {
    height: 100vh;
    position: relative;
    background-color: transparent;
    @media screen and (max-width: 540px) {
      height: 40vh;
      margin-top: 7vh;
      background-image: url(../public/assets/Blog/blog_landing_mobile.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    h1 {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.8vw;
      font-weight: 500;
      width: 85%;
      z-index: 2;
      font-family: "monogram", sans-serif;
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
    img {
      height: 25vw;
      width: 50vw;
      image-rendering: optimizeQuality;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
    .home_pg_div {
      height: 25vw;
      width: 50vw;
      position: absolute;
      z-index: -1;
      top: 55%;
      left: 45%;
      transform: translate(-50%, -50%);
      background: linear-gradient(
        90deg,
        #063c5b -8.96%,
        rgba(227, 232, 236, 0) 144.36%
      );

      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }
  .content {
    width: 100%;
    position: relative;
    display: flex;
    gap: 2vw;
    justify-content: center;
    align-items: center;
    height: 100%; /* Set the height of the parent container */
    overflow: hidden; /* Disable scrolling for the parent container */
    @media screen and (max-width: 540px) {
      flex-direction: column;
    }
    .bg {
      position: absolute;
      width: auto;
      display: flex;
      overflow: visible;
      height: 100%;
      z-index: -1;
      justify-content: center;
      padding: 2vw 1vw;
      @media screen and (max-width: 540px) {
        padding-top: 60px;
      }
    }
    h1 {
      font-size: 2vw;
      font-family: "artifakt", sans-serif;
      @media screen and (max-width: 540px) {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    hr {
      border: 0.5px solid black;
      margin: 20px 0px;
    }
    p {
      font-family: "artifakt", sans-serif;
      text-align: justify;
      font-size: 1.2vw;
      @media screen and (max-width: 540px) {
        font-size: 3.5vw;
        font-style: bold;
        font-weight: 500;
      }
    }
    img {
      padding: 2vw;
      border-radius: 5px;
      @media screen and (max-width: 540px) {
        padding: 10px;
      }
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: end;
      button {
        padding: 5px 20px;
        margin: 10px 0px;
        border-radius: 10px;
        border: 1px solid black;
      }
    }
    .left {
      width: 40vw;
      padding: 1vw;
      @media screen and (max-width: 540px) {
        width: 90vw;
      }
      .tile {
        padding: 30px 20px;
      }
    }
    .right {
      width: 40vw;
      @media screen and (max-width: 540px) {
        width: 90vw;
      }
    }
  }
}

#Ev_users {
  .Landing {
    font-family: "monogram", sans-serif;
    background-image: url(../public/assets/EV_users/Ev_user_home.png);
    position: relative;
    h1 {
      top: 30%;
      left: 45%;
      font-size: 2vw;
      font-weight: 500;
      width: 70%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media screen and (max-width: 540px) {
        font-size: 1rem;
        color: white;
      }
    }
    @media screen and (max-width: 540px) {
      margin-top: 5vh;
    }
  }
  .content {
    font-family: "artifakt", sans-serif;
    .part1 {
      background: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 0) -61.39%,
        #dfcbcb 190%
      );
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: 90vh;
      align-items: center;
      @media screen and (max-width: 540px) {
        min-height: fit-content;
        background: white;
        // margin-top: 0px;
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        @media screen and (max-width: 540px) {
          width: 90%;
          font-size: 0.9rem;
          font-weight: 600;
          margin: 20px 0;
          // margin-top: 0px;
        }
      }
      .tiles {
        overflow: visible;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 60vw;
        @media screen and (max-width: 540px) {
          // margin-top: 0px;
          flex-direction: column;
          gap: 10px;
          margin: 20px 0;
        }
        .tile {
          position: relative;
          overflow: visible;
          margin: 0 4vw;
          @media screen and (max-width: 540px) {
          }
          svg {
            overflow: visible;
            @media screen and (max-width: 540px) {
              // width: 40vw;
              height: 20vh;
              width: auto;
              // margin-top: 0px;
            }
          }
          p {
            position: absolute;
            top: 50%;
            left: -50%;
            font-size: 1.4rem;
            font-weight: 700 !important;
            text-align: left;
            @media screen and (max-width: 540px) {
              font-size: 0.9rem;
              font-weight: 700 !important;
              width: 80vw;
              font-style: bold;
              left: -20%;
              // margin-top: 0px;
            }
          }
        }
      }
    }
    .part_2 {
      width: 100%;
      display: flex;
      @media screen and (max-width: 540px) {
        flex-direction: column;
      }
      img {
        width: 60vw;
        @media screen and (max-width: 540px) {
          width: 100vw;
          // margin-top: 0px;
        }
      }
      .points {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .text {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f1ebeb;
          font-weight: 700;
          &.odd {
            background-color: #e1d4d4;
          }
          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: 540px) {
              // margin-top: 0px;
              font-size: 1rem;
              text-align: center;
              margin: 20px 0;
            }
          }
        }
      }
    }
    .part_3 {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      min-height: 80vh;
      h1 {
        font-size: 18px;
        font-weight: 700;
        @media screen and (max-width: 540px) {
          font-size: 4vw;
          width: 80%;
          font-weight: 700;
          text-align: center;
          margin: 10px 0;
          // margin-top: 0px;
        }
      }
      .tiles {
        overflow: hidden;
        width: 100vw;
        display: flex;
        justify-content: space-evenly;
        align-items: start;
        gap: 30px;
        @media screen and (max-width: 540px) {
          // margin-top: 0px;
          flex-direction: column;
          align-items: center;
        }
        .tile {
          overflow: hidden;
          width: 100%;
          svg {
            width: 30px;
            margin: 20px 5px;
            @media screen and (max-width: 540px) {
              margin: 20px auto;
              // margin-top: 0px;
            }
          }
          width: 20vw;
          @media screen and (max-width: 540px) {
            width: 90vw;
            // margin-top: 0px;
          }
          p {
            font-size: 1rem;
            text-align: justify;
          }
        }
      }
    }
  }
}

#Team {
  .landing {
    background-image: url(../public/assets/Team/landing.png);
    color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }
    h1 {
      width: 100%;
      font-family: "artifakt";
      margin-top: 35%;
      // margin-left: 10vw;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
      @media screen and (max-width: 540px) {
        margin-top: 55%;
        width: 100%;
        font-size: 0.85rem;
      }
    }
  }

  .container {
    height: 555vh;
    background-color: #000;
    position: relative;
  }

  .box {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    offset-path: path(
      "M0 5H947C1041.17 15.3333 1229.5 86.3 1229.5 287.5C1229.5 550.5 1041.17 566 947 579.5L434.5 592C335.667 589.5 111 643 111 851C111 1094.2 252 1154 434.5 1154L947 1168C1041.17 1162.33 1219.77 1241.75 1229.5 1429C1241.5 1660 1041.17 1712.33 947 1718L434.5 1736.5C335.667 1738.67 80.9653 1764.9 103 2034.5C120 2242.5 296.167 2290 395 2286.5L947 2307.5C1041.17 2310.33 1229.5 2366.73 1229.5 2602.5C1229.5 2795.5 1041.17 2865.17 947 2859.5L395 2878.5C291 2878.5 87.5 2948 103 3164.5C118.5 3381 308.333 3429.67 395 3428.5L947 3447C1041.17 3453.33 1248.5 3498 1229.5 3765.5C1214.56 3975.77 1061 3982.5 947 3999H103"
    );
  }

  .teamMembers {
    overflow: hidden;
    height: auto;
    @media screen and (max-width: 540px) {
      height: auto;
    }
    .head {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .subHeading {
        width: 40%;
        height: auto;

        @media screen and (max-width: 540px) {
          width: 80%;
          font-size: 0.85rem;
        }

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 0.5px solid #ffffff;
        border-radius: 50px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        /* Add the box shadow */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
      }
    }

    .teamGrid {
      .web {
        // display: none !important;
        @media screen and (max-width: 540px) {
          display: none !important;
        }
      }

      .mob {
        @media screen and (min-width: 540px) {
          display: none !important;
        }
      }

      margin-top: 5%;
      overflow: visible;
      grid-template-columns: repeat(auto-fit, minmax(1fr, 200px));
      .grid {
        margin-top: -3%;
        margin-bottom: -3%;
        width: 100vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 3vw;
        @media screen and (max-width: 540px) {
          margin-top: 2%;
          margin-bottom: -15%;
        }
        img {
          width: 28vw;
          @media screen and (max-width: 540px) {
            width: 47vw;
            // font-size: 0.85rem;
          }
        }

        .member {
          overflow: visible;
          display: flex;
          flex-direction: column;
          h1 {
            font-family: "Artifakt";
            font-size: 1rem;
            width: 23vw;
            justify-self: flex-end;
            align-self: baseline;
            text-align: justify;
            margin-left: 12%;
            // margin-right: 2vw;
            min-height: 18vh;
          }
          // position: relative;
          .linkedin {
            font-size: 1.8rem;
            align-self: flex-end;
            transform: translate(-110%, -200%);
            @media screen and (max-width: 540px) {
              font-size: 0.85rem;
            }
          }
        }
      }

      .scnd,
      .thd {
        margin-top: 10vh;
        @media screen and (max-width: 540px) {
          margin-top: 5%;
          margin-bottom: -3%;
        }
      }
      .scnd{
        @media screen and (max-width: 540px) {
          margin-top: 15%;
        }
      }
    }
  }

  .partners {
    height: 100vh;
    .heading {
      width: 35vw;
      height: auto;

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      // line-height: 31px;
      text-align: center;

      color: #000000;
      border: 2px solid #8e8787;
      border-radius: 5px;

      margin-top: 10vh;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 50vw;
    }

    .fstLine {
      height: 5vh;
      margin-top: 15vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }

    .container {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lft,
      .rght {
        min-width: 10vw;
        height: 40vh;
        background-color: #f9f9f9;
      }
      .parteners_li {
        min-width: 80vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          overflow: hidden !important;
          height: 100%;
          width: 100%;
          margin: 20px;
          padding: 20px;
        }
        .carouselKaItem {
          overflow: visible;
          width: 80vw;
          height: 30vh;
          .slick-dots {
            // overflow: visible;
          }
        }
      }
    }

    .lstLine {
      height: 5vh;
      margin-top: 5vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }
  }

  .journey {
    height: 290vh;
    .cols {
      display: flex;
      margin-top: 20vh;
      height: 240vh;

      svg {
        scale: 100%;
        width: 85%;
        height: 150%;
        position: absolute;
        right: 0;
        margin-top: 15%;
      }

      .right,
      .left {
        height: 240vh;
        width: 150vw;
        display: flex;
        flex-direction: column;
      }

      .right {
        .scndAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 60vh;
          .achieve {
            position: absolute;
            width: 30vw;
            margin-left: 13vw;
            margin-bottom: 10vh;
          }
        }
        .thdAchivement {
          position: relative;
          width: 30vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }
        .sxthAchivement {
          position: relative;
          width: 30vw;
          align-self: flex-end;
          margin-top: 7vh;
          margin-right: 4vw;
        }
      }

      .left {
        .fstAchivement {
          width: 20vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }

        .frthAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .thdBox {
            position: relative;
            width: 40vw;
            margin-top: 15vh;
          }
          .achieve {
            position: absolute;
            width: 27vw;
            margin-left: 12vw;
            // margin-bottom: 10vh;
          }
        }

        .fthAchivement {
          position: relative;
          width: 20vw;
          align-self: flex-end;
          margin-top: 5vh;
          margin-left: 15vw;
        }
      }
    }

    .downHead {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      h1 {
        width: 42%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 2px solid #8e8787;
        border-radius: 5px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
          padding-left: 1vw;
          font-weight: 400;
        }
      }
    }
  }
}

#Journey {
  overflow: visible;
  .landing {
    background-image: url(../public/assets/Journey/bg.png);
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }

    h1 {
      width: 100%;
      font-family: "artifakt";
      margin-top: 20%;
      // margin-left: 10vw;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
      @media screen and (max-width: 540px) {
        margin-top: 25%;
        width: 100%;
        font-size: 0.85rem;
      }
    }
  }

  .head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .subHeading {
      width: 40%;
      height: auto;

      @media screen and (max-width: 540px) {
        width: 80%;
        font-size: 0.85rem;
      }

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      text-align: center;

      color: #000000;
      border: 0.5px solid #ffffff;
      border-radius: 50px;

      margin-top: 10vh;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;

      /* Add the box shadow */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
    }
  }

  .container {
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    @media screen and (max-width: 540px) {
      transform: scale(0.8);
      // padding-top: 0%;
      width: 100%;
      height: 100%;
    }
    margin-top: 10%;
    margin-bottom: 10%;
    overflow: visible;
    height: auto;
    background-color: #ffffff;
    position: relative;

    .grow {
      @media screen and (max-width: 540px) {
        // position: absolute;
        transform: scale(0.3);
        // -ms-transform: scale(0.5);
        // -webkit-transform: scale(0.5);
        // stroke-width: 2px;
        // height: 150px;
        // width: 100px;
        // text-align: center;
        // margin: 0 auto;
      }
    }

    svg {
      position: absolute;
      @media screen and (max-width: 540px) {
        // height: 40;
        width: 100%;
        // position: fixed;;
        // font-size: 0.85rem;
      }
    }

    .milestonesRow {
      overflow: visible;
      padding-top: 13%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 17.75rem;
      @media screen and (max-width: 540px) {
        gap: 0rem !important;
        padding-top: -50% !important;
      }

      .partnerSet {
        @media screen and (max-width: 540px) {
          // padding-top: -100%;
          transform: scale(0.4);
          margin-top: 0%;
          // position: relative;
          // margin-top: -100%;
        }
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5rem;

        h1 {
          font-family: "Artifakt";
          font-size: 1.5rem;
          font-weight: 100;
          text-align: left;
        }

        .fg {
          overflow: visible !important;
          z-index: 1;
        }

        .bg {
          overflow: visible;
          z-index: 0;
          position: absolute;
        }
      }
    }
  }

  .box {
    // width: 50px;
    // height: 50px;
    // border-radius: 10px;
    position: absolute;
    // background: white;
    top: 0;
    left: 0;

    offset-path: path(
      "M0 5H947C1041.17 15.3333 1229.5 86.3 1229.5 287.5C1229.5 550.5 1041.17 566 947 579.5L434.5 592C335.667 589.5 111 643 111 851C111 1094.2 252 1154 434.5 1154L947 1168C1041.17 1162.33 1219.77 1241.75 1229.5 1429C1241.5 1660 1041.17 1712.33 947 1718L434.5 1736.5C335.667 1738.67 80.9653 1764.9 103 2034.5C120 2242.5 296.167 2290 395 2286.5L947 2307.5C1041.17 2310.33 1229.5 2366.73 1229.5 2602.5C1229.5 2795.5 1041.17 2865.17 947 2859.5L395 2878.5C291 2878.5 87.5 2948 103 3164.5C118.5 3381 308.333 3429.67 395 3428.5L947 3447C1041.17 3453.33 1248.5 3498 1229.5 3765.5C1214.56 3975.77 1061 3982.5 947 3999H103"
    );

    @media screen and (max-width: 540px) {
      transform: scale(0.1);
    }
  }

  .teamMembers {
    overflow: visible;
    height: 280vh;
    .subHeading {
      width: 15vw;
      height: auto;

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      // line-height: 31px;
      text-align: center;

      color: #000000;
      border: 1px solid #8e8787;
      border-radius: 5px;

      margin-top: 10vh;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 5vw;
    }

    .teamGrid {
      margin-top: 10vh;
      overflow: visible;
      grid-template-columns: repeat(auto-fit, minmax(1fr, 200px));
      .grid {
        width: 100vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 3vw;

        img {
          width: 28vw;
        }

        .member {
          overflow: visible;
          display: flex;
          flex-direction: column;
          h1 {
            font-family: "Artifakt";
            font-size: 1rem;
            width: 23vw;
            justify-self: flex-end;
            align-self: baseline;
            text-align: justify;
            margin-left: 12%;
            // margin-right: 2vw;
            min-height: 18vh;
          }
          // position: relative;
          .linkedin {
            font-size: 1.8rem;
            align-self: flex-end;
            transform: translate(-110%, -200%);
          }
        }
      }

      .scnd,
      .thd {
        margin-top: 10vh;
      }
    }
  }

  .partners {
    height: 100vh;
    .heading {
      width: 35vw;
      height: auto;

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      // line-height: 31px;
      text-align: center;

      color: #000000;
      border: 2px solid #8e8787;
      border-radius: 5px;

      margin-top: 10vh;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 50vw;
    }

    .fstLine {
      height: 5vh;
      margin-top: 15vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }

    .container {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lft,
      .rght {
        min-width: 10vw;
        height: 40vh;
        background-color: #f9f9f9;
      }
      .parteners_li {
        min-width: 80vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          overflow: hidden !important;
          height: 100%;
          width: 100%;
          margin: 20px;
          padding: 20px;
        }
        .carouselKaItem {
          overflow: visible;
          width: 80vw;
          height: 30vh;
          .slick-dots {
            // overflow: visible;
          }
        }
      }
    }

    .lstLine {
      height: 5vh;
      margin-top: 5vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }
  }

  .journey {
    height: 290vh;
    .cols {
      display: flex;
      margin-top: 20vh;
      height: 240vh;

      svg {
        scale: 100%;
        width: 85%;
        height: 150%;
        position: absolute;
        right: 0;
        margin-top: 15%;
      }

      .right,
      .left {
        height: 240vh;
        width: 150vw;
        display: flex;
        flex-direction: column;
      }

      .right {
        .scndAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 60vh;
          .achieve {
            position: absolute;
            width: 30vw;
            margin-left: 13vw;
            margin-bottom: 10vh;
          }
        }
        .thdAchivement {
          position: relative;
          width: 30vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }
        .sxthAchivement {
          position: relative;
          width: 30vw;
          align-self: flex-end;
          margin-top: 7vh;
          margin-right: 4vw;
        }
      }

      .left {
        .fstAchivement {
          width: 20vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }

        .frthAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .thdBox {
            position: relative;
            width: 40vw;
            margin-top: 15vh;
          }
          .achieve {
            position: absolute;
            width: 27vw;
            margin-left: 12vw;
            // margin-bottom: 10vh;
          }
        }

        .fthAchivement {
          position: relative;
          width: 20vw;
          align-self: flex-end;
          margin-top: 5vh;
          margin-left: 15vw;
        }
      }
    }

    .downHead {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      h1 {
        width: 42%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 2px solid #8e8787;
        border-radius: 5px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
          padding-left: 1vw;
          font-weight: 400;
        }
      }
    }
  }
}

#Journey_mob {
  overflow: visible;
  .landing {
    background-image: url(../public/assets/Journey/bg.png);
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }

    h1 {
      width: 100%;
      font-family: "artifakt";
      margin-top: 20%;
      // margin-left: 10vw;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
      @media screen and (max-width: 540px) {
        margin-top: 25%;
        width: 100%;
        font-size: 0.85rem;
      }
    }
  }

  .head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .subHeading {
      width: 40%;
      height: auto;

      @media screen and (max-width: 540px) {
        width: 80%;
        font-size: 0.85rem;
      }

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      text-align: center;

      color: #000000;
      border: 0.5px solid #ffffff;
      border-radius: 50px;

      margin-top: 7%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin-bottom: 10%;

      /* Add the box shadow */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
    }
  }

  .container {
    @media screen and (max-width: 540px) {
      // transform: scale(0.80);
      // padding-top: 0%;
      // width: 100%;
      // height: 100%;
    }
    margin-top: 0%;
    margin-bottom: 0%;
    overflow: visible;
    height: auto;
    background-color: #ffffff;
    position: relative;

    // .grow {
    //   @media screen and (max-width: 540px) {
    //     // position: absolute;
    //     transform: scale(0.3);
    //     // -ms-transform: scale(0.5);
    //     // -webkit-transform: scale(0.5);
    //     // stroke-width: 2px;
    //     // height: 150px;
    //     // width: 100px;
    //     // text-align: center;
    //     // margin: 0 auto;
    //   }
    // }

    svg {
      position: absolute;
      @media screen and (max-width: 540px) {
        // height: 40;
        width: 100%;
        // position: fixed;;
        // font-size: 0.85rem;
      }
    }

    .milestonesRow {
      overflow: visible;
      padding-top: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4.7rem;

      .partnerSet {
        @media screen and (max-width: 540px) {
          // padding-top: -100%;
          // transform: scale(0.4);
          // margin-top: 0%;
          // position: relative;
          // margin-top: -100%;
        }

        img {
          width: 25%;
        }

        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0rem;

        h1 {
          font-family: "Artifakt";
          font-size: 0.8rem;
          font-weight: 100;
          text-align: left;
        }

        .fg {
          overflow: visible !important;
          z-index: 1;
        }

        .bg {
          width: 80% !important;
          overflow: visible;
          z-index: 0;
          position: absolute;
        }
      }
    }
  }

  .last {
    padding-bottom: 15%;
  }

  .boxMob {
    // width: 50px;
    // height: 50px;
    // border-radius: 10px;
    position: absolute;
    // background: white;
    top: 0;
    // transform: scale(0.35) !important;
    left: 0;

    offset-path: path(
      "M3.34809 1H248.724C294.218 1.0001 331.641 25.3859 331.641 85.7359C331.641 164.623 284.239 173.321 248.724 173.321L98.2992 177.071C69.2904 176.321 3.34809 186.459 3.34809 259.407C3.34809 332.355 44.7333 345.642 98.2992 345.642L248.724 349.842C276.363 348.142 328.786 371.964 331.641 428.129C335.163 497.417 276.363 513.115 248.724 514.814L98.2992 520.363C69.2904 521.013 1 525.012 1 606.149C1 671.838 57.6967 686.386 86.7055 685.336L248.724 691.635C287.321 691.635 331.641 706.252 331.641 776.97C331.641 838.01 280.13 857.207 248.724 857.207L86.7055 862.906C31.9655 862.906 1 882.703 1 948.692C1 1014.68 61.2678 1028.23 86.7055 1027.88L248.724 1033.43C276.363 1035.33 337.218 1048.73 331.641 1128.96C327.258 1192.03 282.184 1194.05 248.724 1199H1"
    );

    @media screen and (max-width: 540px) {
      transform: scale(0.35);
      left: 4%;
    }
  }
  .partners {
    height: 100vh;
    .heading {
      width: 35vw;
      height: auto;

      font-family: "Artifakt";
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      // line-height: 31px;
      text-align: center;

      color: #000000;
      border: 2px solid #8e8787;
      border-radius: 5px;

      margin-top: 10vh;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 50vw;
    }

    .fstLine {
      height: 5vh;
      margin-top: 15vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }

    .container {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lft,
      .rght {
        min-width: 10vw;
        height: 40vh;
        background-color: #f9f9f9;
      }
      .parteners_li {
        min-width: 80vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          overflow: hidden !important;
          height: 100%;
          width: 100%;
          margin: 20px;
          padding: 20px;
        }
        .carouselKaItem {
          overflow: visible;
          width: 80vw;
          height: 30vh;
          .slick-dots {
            // overflow: visible;
          }
        }
      }
    }

    .lstLine {
      height: 5vh;
      margin-top: 5vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      hr {
        height: 3px;
        width: 70%;
        border: none; /* Removes the default border */
        border-top: 3px solid #000000; /* Sets the top border to a solid line */
      }
      .sqr {
        height: 14px;
        width: 13px;
        background-color: #000000;
      }
    }
  }
  .journey {
    height: 290vh;
    .cols {
      display: flex;
      margin-top: 20vh;
      height: 240vh;

      svg {
        scale: 100%;
        width: 85%;
        height: 150%;
        position: absolute;
        right: 0;
        margin-top: 15%;
      }

      .right,
      .left {
        height: 240vh;
        width: 150vw;
        display: flex;
        flex-direction: column;
      }

      .right {
        .scndAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 60vh;
          .achieve {
            position: absolute;
            width: 30vw;
            margin-left: 13vw;
            margin-bottom: 10vh;
          }
        }
        .thdAchivement {
          position: relative;
          width: 30vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }
        .sxthAchivement {
          position: relative;
          width: 30vw;
          align-self: flex-end;
          margin-top: 7vh;
          margin-right: 4vw;
        }
      }

      .left {
        .fstAchivement {
          width: 20vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }

        .frthAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .thdBox {
            position: relative;
            width: 40vw;
            margin-top: 15vh;
          }
          .achieve {
            position: absolute;
            width: 27vw;
            margin-left: 12vw;
            // margin-bottom: 10vh;
          }
        }

        .fthAchivement {
          position: relative;
          width: 20vw;
          align-self: flex-end;
          margin-top: 5vh;
          margin-left: 15vw;
        }
      }
    }

    .downHead {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      h1 {
        width: 42%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 2px solid #8e8787;
        border-radius: 5px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
          padding-left: 1vw;
          font-weight: 400;
        }
      }
    }
  }
}

#BoardMember {
  .landing {
    background-image: url(../public/assets/Board_members/landing.png);
    color: #000;
    @media screen and (max-width: 540px) {
      height: 40vh;
      width: 100vw;
    }
    h1 {
      width: 100%;
      text-align: center;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "artifakt";
      margin-top: 40vh;
      color: #525050;
      @media screen and (max-width: 540px) {
        margin-top: 25%;
        width: 100%;
        font-size: 0.85rem;
        top: 40%;
        font-weight: 500;
      }
    }
  }

  .members {
    display: flex;
    overflow: hidden;
    height: auto;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 540px) {
        margin-top: -15%;
        // margin-bottom: -3%;
      }
      .subHeading {
        width: 40%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        text-align: center;

        color: #000000;
        // border: 0.5px solid #000000;
        border-radius: 50px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        @media screen and (max-width: 540px) {
          width: 80%;
          font-size: 0.9rem;
        }

        /* Add the box shadow */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
      }
    }

    .directorGrid {
      margin-top: 10vh;
      overflow: visible;
      grid-template-columns: repeat(auto-fit, minmax(1fr, 200px));
      .grid {
        width: 100vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 15vw;
        @media screen and (max-width: 540px) {
          margin-top: -15%;
          // margin-bottom: -3%;
        }

        img {
          width: 28vw;
          @media screen and (max-width: 540px) {
            width: 47vw;
            // font-size: 0.85rem;
          }
        }

        .member {
          overflow: visible;
          display: flex;
          flex-direction: column;
          h1 {
            font-family: "Artifakt";
            font-size: 1rem;
            width: 23vw;
            justify-self: flex-end;
            align-self: baseline;
            text-align: justify;
            margin-left: 12%;
            // margin-right: 2vw;
            min-height: 18vh;
          }
          // position: relative;
          .linkedin {
            font-size: 1.8rem;
            align-self: flex-end;
            transform: translate(-110%, -200%);
            @media screen and (max-width: 540px) {
              // width: 47vw;
              font-size: 0.85rem;
              transform: translate(-110%, -200%);
            }
          }
        }
      }

      .scnd,
      .thd {
        margin-top: 10vh;
      }
    }
    .advisorGrid {
      margin-top: 10vh;
      overflow: visible;
      grid-template-columns: repeat(auto-fit, minmax(1fr, 200px));
      .grid {
        width: 100vw;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 5vw;

        @media screen and (max-width: 540px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          margin-top: -15%;
        }

        .m1 {
          @media screen and (max-width: 540px) {
            grid-area: 1 / 1 / 2 / 2;
          }
        }

        .m2 {
          @media screen and (max-width: 540px) {
            grid-area: 1 / 2 / 2 / 3;
          }
        }

        .m3 {
          @media screen and (max-width: 540px) {
            grid-area: 2 / 1 / 3 / 2;
          }
        }

        img {
          width: 28vw;
          @media screen and (max-width: 540px) {
            width: 47vw;
            // font-size: 0.85rem;
          }
        }

        .member {
          overflow: visible;
          display: flex;
          flex-direction: column;
          h1 {
            font-family: "Artifakt";
            font-size: 1rem;
            width: 23vw;
            justify-self: flex-end;
            align-self: baseline;
            text-align: justify;
            margin-left: 12%;
            // margin-right: 2vw;
            min-height: 18vh;
          }
          // position: relative;
          .linkedin {
            font-size: 1.8rem;
            align-self: flex-end;
            transform: translate(-110%, -200%);
            @media screen and (max-width: 540px) {
              // width: 47vw;
              font-size: 0.85rem;
            }
          }
        }
      }

      .scnd,
      .thd {
        margin-top: 10vh;
      }
    }
  }

  .partners {
    height: auto;
    .head {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 540px) {
        margin-top: -15%;
        margin-bottom: 5%;
      }
      .subHeading {
        width: 40%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        text-align: center;

        color: #000000;
        // border: 0.5px solid #000000;
        border-radius: 50px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        @media screen and (max-width: 540px) {
          width: 80%;
          font-size: 0.9rem;
        }

        /* Add the box shadow */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
      }
    }

    .web {
      // display: none !important;
      @media screen and (max-width: 540px) {
        display: none !important;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 5%;
        width: 70%;
      }
    }

    .mob {
      @media screen and (min-width: 540px) {
        display: none !important;
        // width: 100vw !important;
      }
    }
  }

  .journey {
    height: 290vh;
    .cols {
      display: flex;
      margin-top: 20vh;
      height: 240vh;

      svg {
        scale: 100%;
        width: 85%;
        height: 150%;
        position: absolute;
        right: 0;
        margin-top: 15%;
      }

      .right,
      .left {
        height: 240vh;
        width: 150vw;
        display: flex;
        flex-direction: column;
      }

      .right {
        .scndAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 60vh;
          .achieve {
            position: absolute;
            width: 30vw;
            margin-left: 13vw;
            margin-bottom: 10vh;
          }
        }
        .thdAchivement {
          position: relative;
          width: 30vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }
        .sxthAchivement {
          position: relative;
          width: 30vw;
          align-self: flex-end;
          margin-top: 7vh;
          margin-right: 4vw;
        }
      }

      .left {
        .fstAchivement {
          width: 20vw;
          margin-top: 5vh;
          margin-left: 13vw;
        }

        .frthAchivement {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .thdBox {
            position: relative;
            width: 40vw;
            margin-top: 15vh;
          }
          .achieve {
            position: absolute;
            width: 27vw;
            margin-left: 12vw;
            // margin-bottom: 10vh;
          }
        }

        .fthAchivement {
          position: relative;
          width: 20vw;
          align-self: flex-end;
          margin-top: 5vh;
          margin-left: 15vw;
        }
      }
    }

    .downHead {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      h1 {
        width: 42%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 2px solid #8e8787;
        border-radius: 5px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
          padding-left: 1vw;
          font-weight: 400;
        }
      }
    }
  }
}

#EV_Manufacturer {
  #EV_manuLanding {
    .landing {
      background-image: url(../public/assets/EV_manufacturer/landing.png);
      text-align: left;
      @media screen and (max-width: 540px) {
        height: 40vh;
        width: 100vw;
      }
      h1 {
        z-index: 1;
        position: absolute;
        top: 20vh;
        left: 17vw;
        color: #000000;
        font-size: 3.5vw;
        font-family: "artifakt";
        @media screen and (max-width: 540px) {
          font-size: 1.1rem;
          top: 40%;
          left: 10%;
          font-weight: 600;
        }
      }
    }
  }
  font-family: "artifakt", sans-serif !important;
  .hero {
    background-image: url(../public/assets/EV_manufacturer/scndBg.png);
    text-align: center;
    overflow: hidden;
    height: 100vh;
    @media screen and (max-width: 540px) {
      height: auto;
      background-image: none;
    }
    .web {
      // display: none !important;
      @media screen and (max-width: 540px) {
        display: none !important;
      }
    }

    .mob {
      @media screen and (min-width: 540px) {
        display: none !important;
        // width: 100vw !important;
      }
      // margin-top: 5%;
      width: 100%;
    }

    .col {
      overflow: hidden;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3vh;

      img {
        z-index: 1;
        margin-top: 15vh;
        width: 50vw;
      }

      .downHead {
        margin-top: -4vh;
        font-weight: 600;
        min-width: 30vw;
        font-family: "Artifakt";
        font-size: 1.4rem;
        letter-spacing: 3px;
      }

      h2 {
        font-family: "Artifakt";
        font-size: 1.2rem;
        font-weight: 600;
        width: 70%;
      }
    }

    svg {
      overflow: hidden;
      width: 60% !important;
      height: auto !important;
      z-index: 0;
      position: absolute;
      margin-left: 25% !important;
      margin-top: 1% !important;
    }
  }

  .scndPg {
    height: auto;
    .container {
      margin-top: 10vh;
      height: auto;
      display: flex;

      & > img {
        width: 25vw;
      }
      @media screen and (max-width: 540px) {
        margin-top: 20px;
        width: 100%;
        // margin-top: 0px;
      }
      .content {
        display: flex;
        flex-direction: column;
        width: 63vw;
        margin-left: 2vw;
        // margin-top: 5vh;
        gap: 10vh;
        justify-content: center;
        @media screen and (max-width: 540px) {
          margin-left: 0;
          gap: 5%;
          width: 100vw;
          & > div {
            margin: 20px 10px;
          }
          img {
            width: 100%;
          }
          // margin-top: 0px;
        }

        .head {
          font-family: "Artifakt";
          font-size: 1.1rem;
          font-weight: 600;
          font-style: bold;
          text-align: center;
          @media screen and (max-width: 540px) {
            font-size: 4.5vw;
            font-weight: 600;
            // margin-top: 0px;
          }
        }

        .downHead {
          font-family: "Artifakt";
          font-size: 1.1rem;
          font-weight: 500;
          @media screen and (max-width: 540px) {
            font-size: 3vw;
            // width: 90%;
            // margin: 0 auto;
            font-weight: 500;
            // margin-top: 0px;
          }
        }
      }
    }
  }
}

#Careers {
  .Landing {
    font-family: "monogram", sans-serif;
    background-image: url(../public/assets/Careers/Career_home.png);
    position: relative;
    h1 {
      top: 30%;
      left: 45%;
      font-size: 3rem !important;
      font-weight: 400;
      width: 70%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media screen and (max-width: 540px) {
        transform: translate(0%, -50%);
        left: 10%;
        font-size: 0.9rem !important;
        font-weight: 600;
        width: 80%;
      }
    }
  }
  .Career_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    .head {
      height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: 540px) {
        height: max-content;
        width: 50vw;
        padding: 20px 5px;
      }
      button {
        padding: 10px 30px;
        border-radius: 40px;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "artifakt", sans-serif;

        background: linear-gradient(
            285.84deg,
            #ffffff 16.64%,
            rgba(255, 255, 255, 0.85) 77.79%
          ),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.4)
          );
        border: 1px solid;
        border-image-source: linear-gradient(
          285.84deg,
          #ffffff 16.64%,
          rgba(255, 255, 255, 0.85) 77.79%
        );
        box-shadow: 0px 4px 4px 0px #00000040;
        box-shadow: 0px 5px 4px 0px #d9d9d980 inset;
        @media screen and (max-width: 540px) {
          padding: 5px 10px;
          font-size: 0.9rem;
          width: 100%;
        }
      }
      p {
        font-family: "artifakt", sans-serif;
        width: 60vw;
        font-size: 1.1rem;
        text-align: center;
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
    }
    .lets_Talk {
      font-family: "artifakt", sans-serif;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: white;
      width: 80vw;
      border-radius: 10px;
      height: 60vh;
      box-shadow: 0px 2px 4px 0px #00000040;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 2.08%,
        rgba(222, 222, 222, 0.6) 100%
      );

      @media screen and (max-width: 540px) {
        height: fit-content;
        flex-direction: column;
        width: 90%;
      }
      .contact {
        font-family: "artifakt", sans-serif;
        width: 45%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        @media screen and (max-width: 540px) {
          align-items: center;
          width: 100%;
        }
        h1.main {
          font-family: "artifakt", sans-serif;
          font-size: 3rem;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            font-size: 1rem;
            margin: 10px;
            width: 100%;
            text-align: center;
          }
        }
        h1 {
          font-family: "artifakt", sans-serif;
          font-weight: 600;
          display: flex;
          gap: 10px;
          @media screen and (max-width: 540px) {
            font-size: 0.8rem;
            margin: 10px;
          }
        }
        .socials {
          font-family: "artifakt", sans-serif;
          display: flex;
          gap: 1.2rem;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 540px) {
            justify-content: space-evenly;
            margin: 10px;
            width: 100%;
          }
          svg {
            height: 20px;
          }
        }
      }
      form.form {
        font-family: "artifakt", sans-serif;
        width: 45%;
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media screen and (max-width: 540px) {
          width: 100%;
          padding: 0px 10px;
          margin: 10px;
        }
        .socials {
          font-family: "artifakt", sans-serif;
          display: flex;
          gap: 1.2rem;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 540px) {
            justify-content: space-evenly;
            margin: 10px;
            width: 100%;
          }
          svg {
            height: 20px;
          }
        }
        h1 {
          text-align: end;
          margin: 30px 0px;
          font-family: "artifakt", sans-serif;
        }
        p {
          font-family: "artifakt", sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
        }
        input {
          font-family: "artifakt", sans-serif;
          border: none;
          width: 100%;
          background-color: transparent;
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          padding: 5px;
        }
        select {
          font-family: "artifakt", sans-serif;
          border: none;
          width: 100%;
          background-color: transparent;
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          padding: 5px;
        }
        .share {
          font-family: "artifakt", sans-serif;
          @media screen and (max-width: 540px) {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          button {
            font-family: "artifakt", sans-serif;
            padding: 5px 30px;
            background-color: black;
            color: white;
            transition: 200ms ease;
            &:hover {
              color: #000000;
              background-color: white;
              border: 1px solid black;
              transition: 200ms ease;
            }
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          .socials {
            font-family: "artifakt", sans-serif;
            display: flex;
            gap: 1rem;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }
    background-image: url(../public/assets/Careers/Career_cars.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-family: "artifakt", sans-serif;
    background-position: 0px 150px;
    // .mid{
    // }

    .mid_section {
      font-family: "artifakt", sans-serif;
      min-height: 80vh;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 540px) {
        min-height: fit-content;
      }
      h1 {
        font-family: "artifakt", sans-serif;
        margin: 100px 0px;
        font-size: 1.4rem;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          margin: 20px 0px;
          font-size: 1.1rem;
        }
      }
      .tiles {
        font-family: "artifakt", sans-serif;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        width: 80%;
        align-items: center;
        .tile {
          font-family: "artifakt", sans-serif;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
            font-family: "artifakt", sans-serif;
            height: 150px;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 2px 4px 0px #00000040;
            margin: 10px;
            position: relative;
            border-radius: 10px;
            overflow: visible;
            @media screen and (max-width: 540px) {
              margin: 20px 10px;
              flex-direction: column;
              background: linear-gradient(
                0deg,
                rgba(225, 225, 225, 0.5) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              p {
                font-size: 4vw;
                text-align: center;
              }
            }
            svg {
              height: 50px;
            }
            svg.odd {
              height: 30px;
              position: absolute;
              top: 90%;
            }
          }
          p {
            font-family: "artifakt", sans-serif;
            margin: 20px 0px;
            font-size: 1.2rem;
          }
        }
      }
    }

    .lets_Talk.two {
      height: auto;
      margin: 20px;
      padding: 20px;
      font-family: "artifakt", sans-serif;
      @media screen and (max-width: 540px) {
        margin: 10px;
        padding: 10px;
      }
      form.form {
        width: 100%;
        h1 {
          font-family: "artifakt", sans-serif;
          text-align: start;
          font-size: 2rem;
          @media screen and (max-width: 540px) {
            font-size: 1.3rem;
            margin: 5px 0;
            text-align: center;
            font-weight: 600;
          }
        }
        @media screen and (max-width: 540px) {
          p {
            font-size: 0.8rem;
          }
          input {
            font-size: 0.8rem;
          }
        }
        .MID {
          font-family: "artifakt", sans-serif;
          display: flex;
          gap: 30px;
          .L,
          .R {
            width: 100%;
          }
          @media screen and (max-width: 540px) {
            gap: 10px;
          }
        }
        .share {
          font-family: "artifakt", sans-serif;
          margin-top: 40px;
          @media screen and (max-width: 540px) {
            margin-top: 20px;
          }
          button {
            background-color: #b53232;
          }
        }
      }
    }
  }
}

#product {
  .Landing {
    margin-top: 5%;
    // background-position: 1% 1%;
    background-image: url(../public/assets/products/Product_home.png);
    // position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 540px) {
      background-size: contain;
      height: 40vh;
      width: 100vw;
    }
  }

  .sec_1 {
    .web {
      // display: none !important;
      @media screen and (max-width: 540px) {
        display: none !important;
      }
    }

    .mob {
      @media screen and (min-width: 540px) {
        display: none !important;
        // width: 100vw !important;
      }
    }
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 3%;
    // justify-content: center;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(255, 0, 0, 0) -23.99%,
      #ffffff 4.39%,
      rgba(255, 218, 218, 0.605739) 57.24%,
      rgba(255, 255, 255, 0) 92.91%,
      rgba(255, 255, 255, 0) 121.76%
    );
    .head {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .subHeading {
        width: 40%;
        height: auto;

        font-family: "Artifakt";
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        text-align: center;

        color: #000000;
        border: 0.5px solid #ffffff;
        border-radius: 50px;

        margin-top: 10vh;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: 540px) {
          width: 80%;
          font-size: 0.85rem;
          padding: 5px 10px;
          margin: 5px 10px;
        }

        /* Add the box shadow */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
      }
    }
    .ttt {
      margin-top: 3%;
      width: 75%;
      // font-family: "Artifakt Element";
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      /* or 30px */
      text-align: center;

      color: #000000;
      @media screen and (max-width: 540px) {
        width: 90%;
        font-weight: 600;
        font-size: 2vw;
      }
    }

    img {
      margin-top: 3%;
      width: 75%;
      height: auto;
      @media screen and (max-width: 540px) {
        // display: none !important;
        width: 100vw !important;
      }
    }
  }

  .sec_2 {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    background: linear-gradient(0deg, #7d0407, #7d0407),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 78.75%);

    h1,
    img {
      margin: 2%;
    }

    h1 {
      font-size: 1.5rem;
      font-family: "Artifakt Element";
      font-style: normal;
      font-weight: 500;
      color: #ffffff;
      @media screen and (max-width: 540px) {
        font-size: 0.8rem;
        font-weight: 600;
      }
    }

    img {
      // margin: 2%;
      width: 10%;
      height: auto;
      @media screen and (max-width: 540px) {
        width: 45%;
      }
    }
  }

  @keyframes animatedGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .sec_3 {
    background: linear-gradient(
      180deg,
      rgba(255, 0, 0, 0) -23.99%,
      #ffffff 4.39%,
      rgba(255, 218, 218, 0.605739) 57.24%,
      rgba(255, 255, 255, 0) 92.91%,
      rgba(255, 255, 255, 0) 121.76%
    );
    background-size: 300% 300%; /* Adjust size as needed */
    animation: animatedGradient 4s ease infinite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .web {
      // display: none !important;
      @media screen and (max-width: 540px) {
        display: none !important;
      }
    }

    .mob {
      @media screen and (min-width: 540px) {
        display: none !important;
        // width: 100vw !important;
      }
    }

    @media screen and (max-width: 540px) {
      margin-bottom: 5%;
      padding-bottom: 5%;
    }

    img {
      margin-bottom: 2%;
      margin-top: 2%;
      width: 70%;
      @media screen and (max-width: 540px) {
        width: 95%;
      }
      height: auto;
    }
  }
}

#Market {
  .Landing {
    background-image: url(../public/assets/market/landing.png);
    background-position: 0px 0px;
    @media screen and (max-width: 540px) {
      background-image: url(../public/assets/market/market_landing_mobile.png);
      background-position: center;
      background-size: contain;
      // margin-top: 0px;
    }
  }

  .body {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .div1 {
    overflow: hidden;
    position: relative;
    width: 90%;
    margin-top: 50px;
    @media screen and (max-width: 540px) {
      margin-top: 1%;
    }
    // border: 1px solid black;
    img {
      width: 100%;
    }
    p.p1 {
      font-family: "artifakt", sans-serif;
      position: absolute;
      top: 30%;
      right: 25%;
      transform: translate(50%, -50%);
      width: 40%;
      font-weight: 600;
      font-size: 1.6vw;
      @media screen and (max-width: 540px) {
        width: 40vw;
        font-size: 0.6rem;
      }
    }
    .p2 {
      position: absolute;
      // transform: translate(-50%,50%);
      bottom: 12%;
      left: 5%;
      width: 60vw;
      @media screen and (max-width: 540px) {
        width: 70vw;
      }
      h1 {
        font-family: "artifakt", sans-serif;
        padding: 20px 0px;
        border-bottom: 1px solid black;
        margin-bottom: 20px;
        font-size: 2.2vw;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          padding: 1px 0px;
          margin-bottom: 1px;
          font-size: 0.8rem;
        }
      }
      p {
        font-family: "artifakt", sans-serif;
        font-size: 1.2vw;
        font-weight: 600;
        text-align: justify;
        @media screen and (max-width: 540px) {
          // margin-top: 0px;
          font-size: 0.5rem;
        }
      }
    }
  }
  p.div2 {
    font-family: "artifakt", sans-serif;
    text-align: justify;
    width: 70vw;
    margin: 10vh 0px;
    font-size: 1.1rem;
    // font-weight: 500;
    @media screen and (max-width: 540px) {
      width: 90vw;
      margin: 40px 0px;
      font-size: 0.9rem;
    }
  }
  .tiles {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #d9d9d9 0%,
      rgba(255, 255, 255, 0.829605) 38%,
      rgba(233, 255, 251, 0.692708) 75.09%,
      rgba(217, 217, 217, 0) 100%
    );

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h1 {
      font-size: 1.4rem;
      text-align: center;
      padding: 10px 30px;
      margin: 30px 10px;
      width: fit-content;
      box-shadow: 0 5px 10px gray;
      border-radius: 50px;
      @media screen and (max-width: 540px) {
        font-size: 1rem;
        text-align: center;
        width: 80%;
        padding: 5px 15px;
        margin: 15px 5px;
        // margin-top: 0px;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    img {
      width: 40vw;
    }
    .text {
      width: 30vw;
      padding: 20px;
      @media screen and (max-width: 540px) {
        width: 90vw;
        // order: -1;
        padding: 20px 0px;
        img {
          width: 100%;
          height: auto;
        }
        // margin-top: 0px;
      }
      p {
        margin: 20px 0;
        // width: 40vw;
        font-weight: 550;
        text-align: justify;
        @media screen and (max-width: 540px) {
          font-weight: 400;
          // margin-top: 0px;
        }
      }
    }
  }
  .tile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    width: 100vw;
    padding: 5vw;

    border-bottom: 3px dashed black;
    .pm {
      font-family: "artifakt", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      h1 {
        font-family: "artifakt", sans-serif;
        border: 1px solid black;
        padding: 10px 30px;
        text-align: center;
        border-radius: 10px;
        font-weight: 600;
        font-size: 1.4rem;
        // margin: 10px;
      }
      p {
        text-align: justify;
      }
    }
    .p {
      font-family: "artifakt", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 10px;
      p {
        text-align: justify;
      }
    }
  }
}
#About {
  font-family: "artifakt";
  #contact_landing {
    background-image: url(../public/assets/about/about_landing.png);
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
      z-index: 1;
      fill: white !important;
      color: white;
      @media screen and (max-width: 540px) {
        & {
          width: 80vw;
        }
      }
    }
  }
  .first_div .gradient {
    background: linear-gradient(
      90deg,
      #b5b5b5 -5.65%,
      rgba(245, 245, 245, 0) 86.3%
    );
    min-height: 90vh;
    width: 100%;
    position: relative;
    @media screen and (max-width: 540px) {
      min-height: auto;
      height: 30vh;
      width: 100vw;
    }
    .bg_text {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      border: 10px solid rgba(255, 255, 255, 0.74);
      padding: 10px;
      // border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.66) -3.05%, rgba(203, 203, 203, 0.08) 100%);
      // border: 1px solid black;
      width: 450px;
      display: flex;
      justify-content: end;
      flex-direction: column;
      height: 250px;
      @media screen and (max-width: 540px) {
        border: 1.5vw solid rgba(255, 255, 255, 0.74);
        padding: 2vw;
        top: 50%;
        // height: 40vh;
        left: 30%;
        border: 5px solid rgba(255, 255, 255, 0.74);
        height: 20vw;
        width: 40vw;
      }
      p {
        color: #870507;
        font-size: 1.4rem;
        font-weight: 500;
        @media screen and (max-width: 540px) {
          font-size: 2vw;
        }
      }
    }
    h1 {
      font-size: 1.6rem;
      z-index: 1;
      position: absolute;
      top: 38%;
      font-weight: 600;
      left: 24%;
      @media screen and (max-width: 540px) {
        left: 8%;
        font-size: 3vw;
      }
      span {
        display: block;
        font-weight: 700;
        font-size: 2.2rem;
        @media screen and (max-width: 540px) {
          font-size: 3.5vw;
        }
      }
    }
    overflow: visible;
    img {
      height: 40vh;
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: 30%;
      @media screen and (max-width: 540px) {
        top: 55%;
        right: 25%;
        height: 35vw;
        // width: 100vw;
      }
    }
  }
  .div_2 {
    height: fit-content;
    overflow: hidden;
    display: flex;
    // flex-direction: column;
    padding: 10vw 0px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 0px;
    background-image: url(../public/assets/about/bwCanon.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    // background: linear-gradient(-45deg, #e8e8e8, #f5f5f5);
    //   background-size: 700% 700%;
    //   animation: gradient 3s linear infinite;
    &.a {
      flex-direction: column;
      gap: 3vw;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }
    @media screen and (max-width: 540px) {
      height: auto;
      flex-direction: column;
      width: 100vw;
    }
    h1 {
      overflow: hidden;
      font-weight: 600;
      width: 30vw;
      text-align: center;
      font-size: 1.9rem;
      @media screen and (max-width: 540px) {
        // height: 40vh;
        font-size: 1.2rem;
        width: 90vw;
      }
    }
    p {
      font-size: 1.1rem;
      overflow: hidden;
      text-align: justify;
      width: 50%;
      font-weight: 600;
      @media screen and (max-width: 540px) {
        // height: 40vh;
        font-size: 0.8rem;
        width: 90vw;
      }
    }
  }
  .div_3 {
    background-image: url(../public/assets/about/about_div_bg.png);
    background-size: auto 90%; /* Adjust the width as needed */
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 540px) {
      background-size: contain;
      overflow: visible;
      margin-top: 10px;
      height: 30vh;
      width: 100vw;
    }
    h1 {
      overflow: hidden;
      margin: 2% 2%;
      text-align: left;
      width: 35%;
      margin-left: 10%;
      font-weight: 600;
      font-size: 2vw;
      @media screen and (max-width: 540px) {
        margin-left: 5%;
        font-size: 3vw;
        width: 50%;
      }
    }
    .insideImg {
      overflow: hidden;
      .p1 {
        text-align: left;
        position: absolute;
        margin-top: 4%;
        left: 50%;
        font-weight: 600;
        width: 35%;
        font-size: 1.2vw;
        @media screen and (max-width: 540px) {
          left: 30%;
          font-size: 2vw;
          width: 60%;
        }
      }
      .p2 {
        overflow: hidden;
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: 540px) {
          overflow: visible;
          margin-top: 10%;
        }
        h1 {
          overflow: hidden;
          padding: 10px 0px;
          border-bottom: 1px solid black;
          margin-bottom: 20px 0;
          font-family: "artifakt", sans-serif;
          padding-right: 50px;
          width: fit-content;
          font-size: 1.9vw;
          margin-right: 0 !important;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            padding-right: 0px;
            font-size: 0.8rem;
            margin-left: 5%;
          }
        }
        p {
          width: 55%;
          overflow: hidden;
          padding: 0px 0px;
          // margin-bottom: 20px 0;
          margin-left: 10%;
          font-family: "artifakt", sans-serif;
          padding-right: 50px;
          font-size: 1vw;
          @media screen and (max-width: 540px) {
            padding-right: 0px;
            margin-left: 5%;
            font-size: 0.5rem;
            width: 90%;
          }
          // width:fit-content;
          // font-size: 24px;
          margin-right: 0 !important;
          font-weight: 600;
        }
      }
    }
  }
  .div_4 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    position: relative;
    min-height: 90vh;
    @media screen and (max-width: 540px) {
      min-height: 25vh;
      height: 25vh;
    }
    img {
      height: 40vh;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      @media screen and (max-width: 540px) {
        height: 10vh;
      }

      //   height: auto;
    }
    & > svg {
      position: absolute;
      left: 0%;
      bottom: 12%;
    }
    .content {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 80%;
      top: 50%;
      transform: translate(-50%, -50%);
      gap: 10px;
      p {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 40vw;
        @media screen and (max-width: 540px) {
          font-size: 0.5rem;
        }
        svg {
          display: inline;
          width: 60px;
          margin-right: 10px;
          @media screen and (max-width: 540px) {
            width: 30px;
            height: auto;
          }
        }
      }
    }
  }

  .div_5 {
    background: linear-gradient(
      0.92deg,
      #ededed -4.77%,
      rgba(245, 245, 245, 0) 77.29%
    );
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 40px;
    // margin-bottom: ;
    min-height: 100vh;
    @media screen and (max-width: 540px) {
      gap: 10px;
      min-height: auto;
      height: 40vh;
      width: 100vw;
    }
    .t {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 2.3vw;
        margin: 10px 0;
        line-height: normal;
        @media screen and (max-width: 540px) {
          font-size: 5vw;
        }
      }
      p {
        text-align: center;
        font-size: 1.2vw;
        width: 70vw;
        @media screen and (max-width: 540px) {
          font-size: 2.5vw;
        }
      }
    }
    .img {
      overflow: visible;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;

      img {
        width: 50vw;
        @media screen and (max-width: 540px) {
          width: 80vw;
        }
      }
      @media screen and (max-width: 540px) {
        height: auto !important;
      }
      .text {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: rgba(255, 255, 255, 0.347);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        padding: 20px;

        border: 1px solid;
        @media screen and (max-width: 540px) {
          padding: 10px;
          width: 60vw;
        }
        border-image-source: linear-gradient(
          285.84deg,
          #b5a2a2 16.64%,
          rgba(176, 176, 176, 0) 77.79%
        );

        border-radius: 30px;
        // background: linear-gradient(285.84deg, #B5A2A2 16.64%, rgba(176, 176, 176, 0) 77.79%),linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));

        box-shadow: 0px 4px 4px 0px #00000040;

        box-shadow: 0px 5px 4px 0px #fbfbfb80 inset;

        h1 {
          margin: 20px 0px;
          font-size: 1.6rem;
          font-weight: 600;
          @media screen and (max-width: 540px) {
            margin: 2px 0px;
            font-size: 1.2rem;
          }
        }
        p {
          margin: 10px 0px;
          text-align: center;
          @media screen and (max-width: 540px) {
            margin: 2px 0px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .radGrad {
    // background: radial-gradient(
    //   50% 50% at 50% 50%,
    //   #d9d9d9 0%,
    //   rgba(255, 255, 255, 0.829605) 38%,
    //   rgba(255, 252, 233, 0.693) 75.09%,
    //   rgba(234, 233, 233, 0) 100%
    // );
    //     background: radial-gradient(50% 50% at 50% 50%,
    //   #D9D9D9 0%,
    //   rgba(255, 255, 255, 0.9) 30%,
    //   rgba(255, 253, 188, 0.8) 65%,
    //   rgba(217, 217, 217, 0) 100%
    // );

    .div_heading {
      .head {
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 50px 0px;
        gap: 30px;
        svg {
          // width: 50vw;
          @media screen and (max-width: 540px) {
            width: 20vw;
            height: 3vw;
          }
        }
        &.odd {
          justify-content: end;
        }
        @media screen and (max-width: 540px) {
          margin: 10px 0px;
          gap: 0px;
        }
        h1 {
          border: 1px solid black;
          background: linear-gradient(
              285.84deg,
              #ffffff 16.64%,
              rgba(255, 255, 255, 0.85) 77.79%
            ),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 0.4)
            );
          border: 1px solid;

          border-image-source: linear-gradient(
            285.84deg,
            #ffffff 16.64%,
            rgba(255, 255, 255, 0.85) 77.79%
          );

          box-shadow: 0px 4px 10px 0px #00000040;

          box-shadow: 0px 5px 4px 0px #d9d9d980 inset;

          border-radius: 50px;
          padding: 10px 40px;
          font-weight: 600;
          font-size: 1.4rem;
          @media screen and (max-width: 540px) {
            border-radius: 10px;
            text-wrap: nowrap;
            padding: 1vw 3vw;
            font-size: 3vw;
            margin: 10px;
            width: 100%;
            text-align: center;
          }
        }
      }
      .content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        img {
          width: 40vw;
          @media screen and (max-width: 540px) {
            width: 90vw;
          }
        }
        .text {
          width: 40vw;

          p {
            margin: 20px 0;
            width: 40vw;
            font-weight: 600;
            text-align: justify;
          }
          @media screen and (max-width: 540px) {
            width: 95vw;
            p {
              font-weight: 400;
              width: 95vw;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    .img_div {
      display: flex;
      justify-content: center;
      padding: 30px 0px;
      margin: 20px 0px;
      .img {
        position: relative;
        // overflow: visible;
        img.p {
          width: 50vw;
        }
        img.abs {
          position: absolute;
          bottom: 0;
          left: 10%;
          z-index: 1;
        }
      }
    }
  }
}

#News {
  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(
      360deg,
      rgba(210, 217, 224, 0.02) -3.63%,
      #d8dee4 62.64%,
      rgba(231, 235, 239, 0.57) 107.28%
    );
    font-family: "artifakt", sans-serif;
    @media screen and (max-width: 540px) {
      min-height: fit-content;
    }
    h1 {
      margin: 30px 0px;
      font-size: 1.4rem;
      width: 70%;
      font-weight: 400;
      @media screen and (max-width: 540px) {
        margin: 20px 0px;
        margin-top: 60px;
        font-size: 1.2rem;
        width: 90%;
      }
    }
    .tiles {
      h1 {
        margin: 30px 0px;
        font-size: 1.4rem;
        font-weight: 600;
        @media screen and (max-width: 540px) {
          margin: 20px 0px;
          font-size: 1.1rem;
        }
      }
      width: 70vw;
      @media screen and (max-width: 540px) {
        width: 90vw;
      }
      div {
        // width: 90% !important;
        margin: 0 auto;
      }
      .row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: 0 auto;
        @media screen and (max-width: 540px) {
          flex-direction: column;
        }
        .b1 {
          width: 60%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        .b2 {
          width: 35%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
      }
      .row-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        width: 90%;
        margin: 0 auto;
        @media screen and (max-width: 540px) {
          flex-direction: column;
        }
        .b1 {
          width: 25%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        .b2 {
          width: 25%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        .b3 {
          width: 40%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
      }
      .b {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        height: 200px;
        margin: 10px 0px;
        background: linear-gradient(
          0deg,
          #ce6161 -0%,
          rgba(245, 245, 245, 0) 77.29%
        );
        overflow: hidden;
        border-radius: 10px;
        z-index: 1;
        h1 {
          padding: 0px 10px;
          font-size: 18px;
          z-index: 3;
          color: white;
          width: 100%;
        }
        .bg {
          // background: linear-gradient(0deg, #CE6161 -4%, rgba(245, 245, 245, 0) 40.29%, rgba(245, 245, 245, 0) 100%);
          background: linear-gradient(
            0deg,
            #ce6161 -4%,
            rgba(245, 245, 245, 0) 40.29%,
            rgba(245, 245, 245, 0) 100%
          );
          position: absolute;
          height: 100%;
          z-index: 0;
          width: 100%;
        }
        img {
          // background: linear-gradient(0deg, #CE6161 -4%, rgba(245, 245, 245, 0) 40.29%, rgba(245, 245, 245, 0) 100%);
          position: absolute;
          height: 100%;
          z-index: 0;
          width: 100%;
        }
      }
    }
  }
  .other_news {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    background-color: #e0e4ea;
    min-height: 100px;
    @media screen and (max-width: 540px) {
      flex-direction: column;
    }
    p {
      padding: 10px 10px;
      font-size: 1rem;
      width: 20%;
      @media screen and (max-width: 540px) {
        width: 100%;
      }
    }
    .line {
      width: 1px;
      height: 70px;
      background-color: #3f4042;
      @media screen and (max-width: 540px) {
        width: 80vw;
        height: 1px;
      }
    }
  }
}

.Loading_pg {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    scale: 0.1;
    @media screen and (max-width: 540px) {
      width: 20vw;
    }
  }
}
